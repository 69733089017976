import { Container } from "../../Container/mod.ts";
import { InputCheckBoxInlinePropsType } from "../types.ts";
import { definition } from "../../definition.ts";
import { JSX, useEffect, useSignal } from "../../deps.ts";
import { BROWSER_RESET_STYLE } from "../constants.ts";

export const InputCheckBoxInline = (
  {
    id,
    value,
    name,
    className = "",
    isFocus = false,
    fields,
    onFocus,
    onBlur,
    onClick,
    onSuccess,
  }:
    & InputCheckBoxInlinePropsType
    & {
      onSuccess?: (id: string, value: string) => void;
    },
) => {
  const focus = useSignal(isFocus);
  const inputValue = useSignal(value);
  const isCheckValue = useSignal(false);
  const { light } = definition.text.font.primary;
  const { color, size } = definition.border;
  const { primary, base1 } = definition.background.color;

  useEffect(() => {
    value && value === "i-agree"
      ? isCheckValue.value = true
      : isCheckValue.value = false;
  }, [value]);

  const labelClassName = "cursor-pointer gap-[0.35rem] flex leading-xs";
  const clipPath =
    "clip-path: polygon(19% 35%, 5% 53%, 42% 92%, 95% 17%, 79% 4%, 41% 59%)";
  const simulateInputClassName = `${
    isCheckValue.value ? color.primary.default : color.base4.default
  } ${size.xs} mt-[0.35rem] self-start${
    isCheckValue.value ? ` ${primary.default}` : ""
  }`;
  const simulateInputCrossClassName = `w-[15px] h-[15px] p-[0.35rem]${
    isCheckValue.value ? ` ${base1.default}` : ""
  }`;
  const inputStyle =
    `${BROWSER_RESET_STYLE} background-color: #fff; margin: 0;`;

  className += ` flex flex-row items-center gap-m ${light}`;
  className.trim();

  const onFocusHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    focus.value = true;
    if (onFocus) onFocus(event);
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    focus.value = false;

    event.currentTarget.checked
      ? value = event.currentTarget.value
      : value = "not-checked";

    if (onSuccess) onSuccess(id, value);
    if (onBlur) onBlur(event);
  };

  const onClickHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    isCheckValue.value = !isCheckValue.value;

    event.currentTarget.checked
      ? value = event.currentTarget.value
      : value = "not-checked";

    if (onSuccess) onSuccess(id, value);
    if (onClick) onClick(event);
  };

  return (
    <>
      <fieldset
        className={className}
      >
        {fields.map(({ label, value }) => {
          return (
            <Container
              element={"label"}
              className={labelClassName}
            >
              {/* Div which simulates an input checkbox */}
              <div
                className={simulateInputClassName}
              >
                <div
                  className={simulateInputCrossClassName}
                  style={isCheckValue.value ? clipPath : ""}
                >
                </div>
              </div>
              <input
                style={inputStyle}
                type="checkbox"
                name={name}
                checked={isCheckValue.value}
                value={value ? value as string : inputValue.value}
                onClick={onClickHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
              />
              {label}
            </Container>
          );
        })}
      </fieldset>
    </>
  );
};
