import {
  IsMaritalStatusSetToMarried,
  IsMaritalStatusSetToPacse,
} from "@stores/mod.ts";
import { InputRadio, NBSP_STRING } from "@components/Input/mod.ts";
import { JSX, useEffect, useSignal } from "@components/deps.ts";
import { InputFormTypeRadioType } from "../../types.ts";
import {
  IsMaritalStatusSetToMarriedOrPacse,
  IsOwnerMainResidenceSetToTrue,
  IsOwnerMoreEstatesSetToTrue,
  IsPocketSecuritySetToTrue,
  IsShortTermProjectLiquiditySetToTrue,
} from "@stores/FormStore.ts";

export const InputFormTypeRadio = (
  {
    legend,
    fields,
    name,
    id = "default",
    state = "default",
    value = "",
    direction = "row",
    isRequired = false,
    isDisabled = false,
    onError,
    onSuccess,
    onBlur,
    onClick,
    lastCheckedAt = 0,
  }: InputFormTypeRadioType,
) => {
  const signalState = useSignal(state);
  const signalValue = useSignal(value);
  const signalFeedbackMessage = useSignal(NBSP_STRING);

  useEffect(() => {
    if (lastCheckedAt != 0) {
      if (!checkError()) {
        onSuccess && onSuccess(id, signalValue.value);
      } else {
        onError && onError(id, signalValue.value);
      }
    }
  }, [lastCheckedAt]);

  useEffect(() => {
    signalValue.value = value;
  }, [value]);

  const onClickHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalValue.value = event.currentTarget.value;

    if (id === "maritalStatus") {
      if (signalValue.value === "marie") {
        IsMaritalStatusSetToMarried.value = true;
        IsMaritalStatusSetToPacse.value = false;
        IsMaritalStatusSetToMarriedOrPacse.value = true;
      } else if (signalValue.value === "pacse") {
        IsMaritalStatusSetToPacse.value = true;
        IsMaritalStatusSetToMarried.value = false;
        IsMaritalStatusSetToMarriedOrPacse.value = true;
      } else {
        IsMaritalStatusSetToPacse.value = false;
        IsMaritalStatusSetToMarried.value = false;
        IsMaritalStatusSetToMarriedOrPacse.value = false;
      }
    }

    if (id === "isOwnerMainResidence") {
      signalValue.value === "oui"
        ? IsOwnerMainResidenceSetToTrue.value = true
        : IsOwnerMainResidenceSetToTrue.value = false;
    }

    if (id === "isOwnerMoreEstates") {
      signalValue.value === "oui"
        ? IsOwnerMoreEstatesSetToTrue.value = true
        : IsOwnerMoreEstatesSetToTrue.value = false;
    }

    if (id === "safetyPocket") {
      signalValue.value === "oui"
        ? IsPocketSecuritySetToTrue.value = true
        : IsPocketSecuritySetToTrue.value = false;
    }

    if (id === "shortTermProjectLiquidity") {
      signalValue.value === "oui"
        ? IsShortTermProjectLiquiditySetToTrue.value = true
        : IsShortTermProjectLiquiditySetToTrue.value = false;
    }

    if (signalValue.value !== "") {
      signalState.value = "default";
      signalFeedbackMessage.value = NBSP_STRING;
    }

    onSuccess ? onSuccess(id, signalValue.value) : null;

    onClick && onClick(event);
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    if (checkError()) {
      onError && onError(id, signalValue.value);
    } else {
      onSuccess && onSuccess(id, signalValue.value);
    }

    onBlur && onBlur(event);
  };

  const checkError = () => {
    if (signalValue.value === "" && isRequired && !isDisabled) {
      signalState.value = "error";
      signalFeedbackMessage.value = "Vous devez choisir une option.";
      return true;
    }
    signalState.value = "default";
    signalFeedbackMessage.value = NBSP_STRING;
    return false;
  };

  return (
    <InputRadio
      value={signalValue.value}
      state={signalState.value}
      legend={isRequired ? `${legend} *` : legend}
      name={name}
      direction={direction}
      onBlur={onBlurHandler}
      onClick={onClickHandler}
      fields={fields}
      feedbackMessage={signalFeedbackMessage.value}
      isRequired={isRequired}
      isDisabled={isDisabled}
    />
  );
};
