import { InputFormTypeAllType } from "../../../types.ts";
import { RadioBlockItemType } from "@config/app/questionTemplates/types.ts";
import { InputCheckBoxInline } from "@components/Input/mod.ts";

export const InputCheckboxForPhone = (
  {
    value,
    data: {
      id,
      label,
      content: {
        choices,
      },
    },
    onSuccess,
  }: InputFormTypeAllType<{
    data: RadioBlockItemType;
  }>,
) => {
  return (
    <InputCheckBoxInline
      name={label}
      id={id}
      value={value}
      fields={choices}
      onSuccess={onSuccess}
    />
  );
};
