import { Container } from "../../Container/Container.tsx";
import { InputPropsType } from "../types.ts";
import { definition } from "../../definition.ts";
import { JSX, useEffect, useRef, useSignal } from "../../deps.ts";
import { Text } from "../../Text/Text.tsx";
import { NBSP_STRING, STATE_COLORS } from "../constants.ts";
import { Helper } from "../../Chart/deps.ts";

export const InputTextAndRange = (
  {
    state = "default",
    value = "",
    feedbackMessage = "",
    inputMode = "text",
    label,
    placeholder = "10 000",
    className = "",
    type = "text",
    onFocus,
    onBlur,
    onInput,
    onChange,
    onKeyDown,
    onKeyUp,
    onClick,
    leadingIcon,
    trailingIcon,
    leadingText,
    trailingText,
    helpText,
    isDisabled = false,
    isFocus = false,
    isSelectedOnClick = false,
  }: InputPropsType,
) => {
  const signalFocus = useSignal(isFocus);
  const signalValue = useSignal(
    value ? Helper.addWhiteSpaceToBigNumber(+value) : "",
  );
  const inputTextRef = useRef(null);

  useEffect(() => {
    signalValue.value = value ? Helper.addWhiteSpaceToBigNumber(+value) : "";
  }, [value]);

  const { light } = definition.text.font.primary;
  const labelClassName = "text-base1-content pb-xs pt-s block";
  const feedbackMessageClassName = "text-left text-danger-default flex-auto";
  const iconTextClassName = "px-m";
  const helpTextClassName =
    "text-right underline pt-xs flex-auto text-base4-default";

  let containerClassName =
    "flex flex-row w-full pr-xs rounded-l-xs rounded-r-xs h-2xl items-center"; //
  let bgColor = "";
  let contentColor = "";

  if (isDisabled !== undefined && isDisabled == true) {
    bgColor = definition.background.color["disabled"].default;
    contentColor = definition.text.color["disabled"].content;
  } else {
    bgColor = definition.background.color["base1"].default;
    contentColor = definition.text.color["base1"].content;
  }

  let ringColor = definition.ring.color[STATE_COLORS[state]].default;
  let borderColor = definition.border.color[STATE_COLORS[state]].default;

  if (
    state == "default" && value != "" && !isDisabled
  ) {
    borderColor = definition.border.color["base1"].content;
    ringColor = definition.ring.color["base1"].content;
  }

  containerClassName += " border-xs " + bgColor + " " + borderColor +
    " " + ringColor +
    " " + contentColor + " " +
    (signalFocus.value ? "ring-s" : "ring-none");

  let classNameInput =
    `outline-none w-full focus:outline-none text-right flex-1${
      leadingIcon || leadingText ? "" : " pl-xs"
    }`;
  classNameInput += " " + bgColor + " " + borderColor + " " + contentColor;

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalFocus.value = false;
    onBlur && onBlur(event);
  };

  const onFocusHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalFocus.value = true;
    onFocus && onFocus(event);
  };

  className += " " + "group" + " " + light;
  className.trim();

  const onInputHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^\d+]/g,
      "",
    );

    if (event.currentTarget.value === "") return;

    value = event.currentTarget.value;

    /*if (typeof value === "string") {
      value.length > 3 ? value = Helper.addWhiteSpaceToBigNumber(+value) : null;
    }*/
    signalValue.value = value;

    onInput && onInput(event);
  };

  const onChangeHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    value = event.currentTarget.value;
    onChange && onChange(event);
  };

  const onKeyUpHandler = (
    event: JSX.TargetedEvent<HTMLInputElement, KeyboardEvent>,
  ) => {
    value = event.currentTarget.value;
    onKeyUp && onKeyUp(event);
  };

  const onKeyDownHandler = (
    event: JSX.TargetedEvent<HTMLInputElement, KeyboardEvent>,
  ) => {
    value = event.currentTarget.value;
    onKeyDown && onKeyDown(event);
  };

  const onClickHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    onClick && onClick(event);
    isSelectedOnClick ? event.currentTarget.select() : null;
  };

  return (
    <Container element="label" className={className ?? ""}>
      <Container className={labelClassName}>
        {label}
      </Container>
      <Container className={containerClassName}>
        {leadingIcon
          ? (
            <Container
              element="span"
              className={iconTextClassName}
            >
              {leadingIcon}
            </Container>
          )
          : null}
        {leadingText
          ? (
            <Container
              element="span"
              className={iconTextClassName}
            >
              {leadingText}
            </Container>
          )
          : null}
        <input
          ref={inputTextRef}
          inputMode={inputMode}
          type={type}
          placeholder={placeholder}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onInput={onInputHandler}
          onKeyDown={onKeyDownHandler}
          onKeyUp={onKeyUpHandler}
          onClick={onClickHandler}
          onChange={onChangeHandler}
          className={classNameInput}
          value={signalValue.value}
          autofocus={isFocus}
          disabled={isDisabled}
        />
        {trailingText
          ? (
            <Container
              element="span"
              className={iconTextClassName}
            >
              {trailingText}
            </Container>
          )
          : null}
        {trailingIcon
          ? (
            <Container
              element="span"
              className={iconTextClassName}
            >
              {trailingIcon}
            </Container>
          )
          : null}
      </Container>
      <Container className="flex flex-row w-full">
        {feedbackMessage !== ""
          ? (
            <Text
              size={"s"}
              className={feedbackMessageClassName}
              element="div"
            >
              {feedbackMessage}
            </Text>
          )
          : NBSP_STRING}
        {helpText
          ? (
            <Container
              className={helpTextClassName}
              element="div"
            >
              {helpText}
            </Container>
          )
          : null}
      </Container>
    </Container>
  );
};
