import { InputFormTypeTextAndRange } from "../../mod.ts";
import { InputFormTypeAllType } from "../../../types.ts";
import { SliderAssistedAmountItemType } from "@config/app/questionTemplates/types.ts";

export const InputPrivateAssets = (
  {
    value,
    data: {
      id,
      label,
      placeholder,
      required,
      disabled,
      config: {
        unit,
        limit: {
          max,
          min,
        },
      },
    },
    onError,
    onSuccess,
    lastCheckedAt,
  }: InputFormTypeAllType<{
    data: SliderAssistedAmountItemType;
  }>,
) => {
  return (
    <InputFormTypeTextAndRange
      onSuccess={onSuccess}
      onError={onError}
      id={id}
      label={label}
      placeholder={placeholder}
      value={value}
      isRequired={required}
      isDisabled={disabled}
      unit={unit}
      max={max}
      min={min}
      lastCheckedAt={lastCheckedAt}
    />
  );
};
