import { Container } from "../../Container/mod.ts";
import { InputCheckBoxInlinePropsType } from "../types.ts";
import { definition } from "../../definition.ts";
import { JSX, useEffect, useRef, useSignal } from "../../deps.ts";
import { BROWSER_RESET_STYLE } from "../constants.ts";
import { Text } from "@components/Text/mod.ts";

export const InputCheckboxHorizontal = (
  {
    id,
    value,
    name,
    className = "",
    isFocus = false,
    fields,
    onFocus,
    onClick,
    onSuccess,
    legend,
  }:
    & InputCheckBoxInlinePropsType<string[]>
    & {
      legend?: string;
      onSuccess?: (id: string, value: string[]) => void;
    },
) => {
  const focus = useSignal(isFocus);
  const inputValues = useSignal(value ?? []);
  const inputsChecked = useSignal([] as boolean[]);
  const inputRef = useRef(null);

  const { light } = definition.text.font.primary;
  const { color, size } = definition.border;
  const { primary, base1 } = definition.background.color;

  useEffect(() => {
    value ? inputValues.value = value : inputValues.value = [];
    const booleans: boolean[] = [];

    fields.map((field) => {
      inputValues.value.includes(field.value as string)
        ? booleans.push(true)
        : booleans.push(false);
    });

    for (const child of inputRef.current.children) {
      const value = child.querySelector("input").value;

      if (inputValues.value.includes(value)) {
        child.classList.add("alreadySet");
      }
    }

    inputsChecked.value = [...booleans];
  }, [value]);

  const labelClassName = "cursor-pointer gap-[0.35rem] flex leading-xs";
  const clipPath =
    "clip-path: polygon(19% 35%, 5% 53%, 42% 92%, 95% 17%, 79% 4%, 41% 59%)";
  const simulateInputClassName = (isChecked: boolean) =>
    `${
      isChecked ? color.primary.default : color.base4.default
    } ${size.xs} mt-[0.35rem] self-start${
      isChecked ? ` ${primary.default}` : ""
    }`;
  const simulateInputCrossClassName = (isChecked: boolean) =>
    `w-[15px] h-[15px] p-[0.35rem]${isChecked ? ` ${base1.default}` : ""}`;
  const inputStyle =
    `${BROWSER_RESET_STYLE} background-color: #fff; margin: 0;`;

  className += ` ${light}`;
  className.trim();

  const onFocusHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    focus.value = true;
    if (onFocus) onFocus(event);
  };

  const onClickHandler = (
    event: JSX.TargetedEvent<HTMLInputElement>,
    i = 0,
  ) => {
    const currentLabel = event.currentTarget.parentNode as HTMLLabelElement;
    const wrapper = currentLabel.parentNode as HTMLDivElement;
    const booleans: boolean[] = [];
    const isValueHasBeenSetYet = (child: HTMLLabelElement) =>
      inputValues.value.includes(child.querySelector("input")?.value as string);

    for (const child of wrapper.children as unknown as HTMLLabelElement[]) {
      if (currentLabel === child) {
        // If current label has been set, remove it from `inputValues` signal.
        if (currentLabel.classList.contains("alreadySet")) {
          booleans.push(false);
          inputValues.value = inputValues.value.filter((item) =>
            item !== event.currentTarget.value
          );
        } else {
          booleans.push(true);
          inputValues.value.push(event.currentTarget.value);
        }

        currentLabel.classList.toggle("alreadySet");
      } else if (isValueHasBeenSetYet(child)) {
        booleans.push(true);
      } else booleans.push(false);
      i++;
    }

    inputsChecked.value = [...booleans];

    if (onSuccess) onSuccess(id, inputValues.value);
    if (onClick) onClick(event);
  };

  return (
    <>
      <fieldset
        className={className}
      >
        <legend className={"text-base1-content pb-xs pt-s block"}>
          <Text>{legend}</Text>
        </legend>
        <div
          ref={inputRef}
          className={"flex py-xs xs:flex-col xs:py-xs gap-s border-xs ring-none bg-base1-default border-base1-content text-base1-content ring-base1-content px-xs rounded-xs"}
        >
          {fields.map(({ label, value }, index) => {
            return (
              <Container
                element={"label"}
                className={labelClassName}
              >
                {/* Div which simulates an input checkbox */}
                <div
                  className={simulateInputClassName(
                    inputsChecked.value[index],
                  )}
                >
                  <div
                    className={simulateInputCrossClassName(
                      inputsChecked.value[index],
                    )}
                    style={inputsChecked.value[index] ? clipPath : ""}
                  >
                  </div>
                </div>
                <input
                  name={name}
                  style={inputStyle}
                  type="checkbox"
                  value={value as string}
                  onClick={onClickHandler}
                  onFocus={onFocusHandler}
                />
                {label}
              </Container>
            );
          })}
        </div>
      </fieldset>
    </>
  );
};
