import { Input, NBSP_STRING } from "@components/Input/mod.ts";
import { JSX, useEffect, useSignal } from "@components/deps.ts";
import { InputFormPropsType } from "../../types.ts";

export const InputFormTypeTel = (
  {
    name,
    label,
    placeholder,
    id = "default",
    state = "default",
    value = "",
    isRequired = false,
    isDisabled = false,
    onError,
    onSuccess,
    onInput,
    onBlur,
    lastCheckedAt = 0,
  }: InputFormPropsType & {
    label: string;
    placeholder: string;
  },
) => {
  const signalState = useSignal(state);
  const signalValue = useSignal(value);
  const signalFeedbackMessage = useSignal(NBSP_STRING);

  useEffect(() => {
    if (lastCheckedAt != 0) {
      if (!checkError()) {
        onSuccess && onSuccess(id, signalValue.value);
      } else {
        onError && onError(id, signalValue.value);
      }
    }
  }, [lastCheckedAt]);

  useEffect(() => {
    signalValue.value = value;
  }, [value]);

  const onInputHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalValue.value = event.currentTarget.value;
    signalState.value = "default";

    signalValue.value !== "" ? signalFeedbackMessage.value = NBSP_STRING : null;

    onInput && onInput(event);
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {

    if (checkError()) {
      onError && onError(id, signalValue.value);
    } else {
      onSuccess &&
        onSuccess(id, signalValue.value);
    }

    onBlur && onBlur(event);
  };

  const checkError = () => {
    if (signalValue.value === "" && isRequired && !isDisabled) {
      signalState.value = "error";
      signalFeedbackMessage.value =
        "Vous devez renseigner un numéro de téléphone.";
      return true;
    }

    signalFeedbackMessage.value = NBSP_STRING;
    return false;
  };

  return (
    <Input
      name={name}
      value={signalValue.value}
      state={signalState.value}
      label={isRequired ? `${label} *` : label}
      placeholder={placeholder}
      onInput={onInputHandler}
      onBlur={onBlurHandler}
      feedbackMessage={signalFeedbackMessage.value}
      isDisabled={isDisabled}
    />
  );
};

