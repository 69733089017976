import questionsJson from "@config/app/questionTemplates/asset-assessment/questions.json" assert {
  type: "json",
};
import {
  ItemElementType,
  ItemType,
} from "@config/app/questionTemplates/mod.ts";
import {
  IsOwnerMainResidenceSetToTrue,
  IsOwnerMoreEstatesSetToTrue,
  IsPocketSecuritySetToTrue,
  IsShortTermProjectLiquiditySetToTrue,
  ResponseStoreType,
} from "@stores/mod.ts";
//import { CODE_ISO2, COUNTRIES } from "@utils/translation";

/**
 * Takes a given `id` and returns the corresponding object.
 * @param id The id of the selected object.
 */
export const dispatchInputData = (
  id: string,
  data = questionsJson as ItemType<ItemElementType>[],
): ItemType<ItemElementType> => {
  return data.filter((el) => el.id === id)[0];
};

export const dispatchInputDataReceivedFromDB = <T extends string | string[] = string>(
  key: string,
  value?: Record<string, T>,
) => {
  if (value && value[key]) {
    return value[key];
  }
  return "";
};

/**
 * Returns a closure witch set the accordion title (with the corresponding number).
 * @param count number for prefixing accordion title.
 */
export const dispatchAccordionTitle = (count = 0) => {
  return (id: string) => {
    count++;
    return `${count} - ${dispatchInputData(id).label}`;
  };
};

/**
 * Returns the required fields count.
 */
export const fieldsRequiredCount = () => {
  let acc = 0;

  acc += IsOwnerMainResidenceSetToTrue.value ? 1 : 0;
  // TODO: require at least one subquestion if all are optionals while main question is set to true
  acc += IsOwnerMoreEstatesSetToTrue.value ? 0 : 0;
  acc += IsPocketSecuritySetToTrue.value ? 1 : 0;
  acc += IsShortTermProjectLiquiditySetToTrue.value ? 1 : 0;

  return questionsJson
    .filter((question) => question.element !== "Section")
    .filter((question) => (
      question.required === true &&
      question.isRelatedItem === false
    )).length + acc;
};

/**
 * Returns the required fields count set by user.
 * @param formRes user stored values.
 */
export const fieldsRequiredUserHasAlreadySet = (formRes: ResponseStoreType) => {
  let count = 0;

  questionsJson
    .filter((el) => el.required === true)
    .map((el) => el.id)
    .map((id) => {
      formRes && formRes[id] ? count++ : null;
    });

  return count;
};

/**
 * Takes a given `id` and checks if it's a required field.
 * @param id The id of the selected object.
 */
export const isFieldRequired = (id: string) => {
  if (id !== "user") {
    if(!dispatchInputData(id)){
      return false;
    }
    return dispatchInputData(id).required;
  }
};

/**
 * Takes a given `id` and checks if it's a radio or select field.
 * @param id The id of the selected object.
 */
export const isFieldHasMultipleChoice = (id: string) => {
  return (
    dispatchInputData(id).element === "RadioInline" ||
    dispatchInputData(id).element === "RadioBlock" ||
    dispatchInputData(id).element === "Dropdown"
  );
};
