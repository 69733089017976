import {
  IsFromUS,
  IsUSCitizen,
  IsNationalitySetNotToFrench,
  IsResidenceSetNotToFrench,
} from "@stores/mod.ts";
import { InputSelect, NBSP_STRING } from "@components/Input/mod.ts";
import { JSX, useEffect, useSignal } from "@components/deps.ts";
import { InputFormTypeSelectType } from "../../types.ts";

export const InputFormTypeSelect = (
  {
    label,
    fields,
    name,
    placeholder,
    id = "default",
    state = "default",
    value = "",
    isRequired = false,
    isDisabled = false,
    onError,
    onSuccess,
    onBlur,
    onChange,
    lastCheckedAt = 0,
  }: InputFormTypeSelectType,
) => {
  const signalState = useSignal(state);
  const signalValue = useSignal(value);
  const signalFeedbackMessage = useSignal(NBSP_STRING);

  useEffect(() => {
    if (lastCheckedAt != 0) {
      if (!checkError()) {
        onSuccess && onSuccess(id, signalValue.value);
      } else {
        onError && onError(id, signalValue.value);
      }
    }
  }, [lastCheckedAt]);

  useEffect(() => {
    signalValue.value = value;
  }, [value]);

  const onChangeHandler = (event: JSX.TargetedEvent<HTMLSelectElement>) => {
    signalValue.value = event.currentTarget.value;

    if (signalValue.value !== "" && onSuccess) {
      onSuccess(id, signalValue.value);

      const isNotFrench = signalValue.value !== "fr" &&
        //Guadeloupe
        signalValue.value !== "gp" &&
        //Martinique
        signalValue.value !== "mq" &&
        //Guyane
        signalValue.value !== "gf" &&
        //Réunion
        signalValue.value !== "re" &&
        //Saint-Martin
        signalValue.value !== "sx" &&
        //Mayotte
        signalValue.value !== "yt";

      //TODO Nouvelle-Calédonie ?
      // signalValue.value !== "nc" &&
      //TODO Polynésie française ?
      // signalValue.value !== "pf"`

      if (id === "taxResidence") {
        signalValue.value === "us"
          ? IsFromUS.value = true
          : IsFromUS.value = false;

        IsResidenceSetNotToFrench.value = isNotFrench;
      }

      if (id === "nationality") {
        IsNationalitySetNotToFrench.value = isNotFrench;
        
        signalValue.value === "us"
          ? IsUSCitizen.value = true
          : IsUSCitizen.value = false;
      }

      signalState.value = "default";
      signalFeedbackMessage.value = NBSP_STRING;
    }

    onChange && onChange(event);
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLSelectElement>) => {
    if (checkError()) {
      onError && onError(id, signalValue.value);
    } else {
      onSuccess && onSuccess(id, signalValue.value);
    }

    onBlur && onBlur(event);
  };

  const checkError = () => {
    if (signalValue.value === "" && isRequired && !isDisabled) {
      signalState.value = "error";
      signalFeedbackMessage.value = "Vous devez choisir une option.";
      return true;
    }

    signalState.value = "default";
    signalFeedbackMessage.value = NBSP_STRING;
    return false;
  };

  return (
    <InputSelect
      state={signalState.value}
      label={isRequired ? `${label} *` : label}
      name={name}
      placeholder={placeholder}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      fields={fields}
      feedbackMessage={signalFeedbackMessage.value}
      value={signalValue.value}
      isRequired={isRequired}
      isDisabled={isDisabled}
    />
  );
};
