import {
  InputDigitalAssets,
  InputLiquidAssets,
  InputMainResidenceValue,
  InputPrivateAssets,
  InputProfessionalAssets,
  InputRentalInvestmentValue,
  InputSavings,
  InputSecondHomeValue,
  RadioInlineIsOwnerMainResidence,
  RadioInlineIsOwnerMoreEstates,
} from "./mod.ts";
import { Container } from "@components/Container/mod.ts";
import {
  RadioInlineItemType,
  SliderAssistedAmountItemType,
} from "@config/app/questionTemplates/mod.ts";
import {
  AssetAssessmentFormSectionPropsType,
  dispatchInputData,
  dispatchInputDataReceivedFromDB,
} from "../mod.ts";
import {
  IsOwnerMainResidenceSetToTrue,
  IsOwnerMoreEstatesSetToTrue,
  ResponseStore,
} from "@stores/mod.ts";
import { useEffect } from "@components/deps.ts";

export const AssetAssessmentFormSection2 = ({
  onSuccessCallback,
  onErrorCallback,
  lastCheckedAt = 0,
  formResponsesValue,
}: AssetAssessmentFormSectionPropsType) => {
  const containerClassName = (num: number) => `grid lg:grid-cols-${num} gap-l`;
  const containerBottomPadding = "l";

  useEffect(() => {
    if (ResponseStore.value) {
      ResponseStore.value["isOwnerMainResidence"] === "oui"
        ? IsOwnerMainResidenceSetToTrue.value = true
        : null;

      ResponseStore.value["isOwnerMoreEstates"] === "oui"
        ? IsOwnerMoreEstatesSetToTrue.value = true
        : null;
    }
  }, []);

  return (
    <Container>
      {/* Part 1 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputLiquidAssets
          value={dispatchInputDataReceivedFromDB(
            "liquidAssets",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "liquidAssets",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 2 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputSavings
          value={dispatchInputDataReceivedFromDB("savings", formResponsesValue)}
          data={dispatchInputData("savings") as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 3 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputDigitalAssets
          value={dispatchInputDataReceivedFromDB(
            "digitalAssets",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "digitalAssets",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 4 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputPrivateAssets
          value={dispatchInputDataReceivedFromDB(
            "privateAssets",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "privateAssets",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 5 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputProfessionalAssets
          value={dispatchInputDataReceivedFromDB(
            "professionalAssets",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "professionalAssets",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 6 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <RadioInlineIsOwnerMainResidence
          value={dispatchInputDataReceivedFromDB(
            "isOwnerMainResidence",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "isOwnerMainResidence",
          ) as RadioInlineItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 7 */}

      {IsOwnerMainResidenceSetToTrue.value
        ? (
          <Container
            spacing={{ bottom: containerBottomPadding }}
            className={containerClassName(1)}
          >
            <InputMainResidenceValue
              value={dispatchInputDataReceivedFromDB(
                "mainResidenceValue",
                formResponsesValue,
              )}
              data={dispatchInputData(
                "mainResidenceValue",
              ) as SliderAssistedAmountItemType}
              onSuccess={onSuccessCallback}
              onError={onErrorCallback}
              lastCheckedAt={lastCheckedAt}
            />
          </Container>
        )
        : null}
      {/* Part 8 */}
      {/* && IsOwnerMoreEstatesSetToTrue.value */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <RadioInlineIsOwnerMoreEstates
          value={dispatchInputDataReceivedFromDB(
            "isOwnerMoreEstates",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "isOwnerMoreEstates",
          ) as RadioInlineItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 9 */}
      {IsOwnerMoreEstatesSetToTrue.value
        ? (
          <>
            <Container
              spacing={{ bottom: containerBottomPadding }}
              className={containerClassName(1)}
            >
              <InputSecondHomeValue
                value={dispatchInputDataReceivedFromDB(
                  "secondHomeValue",
                  formResponsesValue,
                )}
                data={dispatchInputData(
                  "secondHomeValue",
                ) as SliderAssistedAmountItemType}
                onSuccess={onSuccessCallback}
                onError={onErrorCallback}
                lastCheckedAt={lastCheckedAt}
              />
            </Container>
            <Container
              spacing={{ bottom: containerBottomPadding }}
              className={containerClassName(1)}
            >
              <InputRentalInvestmentValue
                value={dispatchInputDataReceivedFromDB(
                  "rentalInvestmentValue",
                  formResponsesValue,
                )}
                data={dispatchInputData(
                  "rentalInvestmentValue",
                ) as SliderAssistedAmountItemType}
                onSuccess={onSuccessCallback}
                onError={onErrorCallback}
                lastCheckedAt={lastCheckedAt}
              />
            </Container>
          </>
        )
        : null}
      {/* Part 10 */}
    </Container>
  );
};
