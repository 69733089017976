import {
  CallOutItemType,
  DatePickerItemType,
  DropdownItemType,
  InputItemType,
  RadioBlockItemType,
} from "@config/app/questionTemplates/mod.ts";
import { CallOut } from "@components/CallOut/mod.ts";
import { Container } from "@components/Container/mod.ts";
import {
  dispatchInputData,
  dispatchInputDataReceivedFromDB,
} from "../dispatcher.ts";
import {
  AssetAssessmentFormSectionPropsType,
  iconExclamation,
} from "../mod.ts";
import {
  InputCheckboxForEmail,
  InputCheckboxForPhone,
  InputDateOfBirth,
  InputEmail,
  InputFirstname,
  InputLastname,
  InputPhone,
  RadioFamilySituation,
  RadioInlineGender,
  RadioMaritalStatus,
  RadioNumberOfChildren,
  RadioPacsStatus,
  RadioProfession,
  RadioProfessionalStatus,
  SelectCountry,
  SelectNationality,
} from "./mod.ts";
import { useEffect } from "@components/deps.ts";
import {
  IsFromUS,
  IsMaritalStatusSetToMarried,
  IsMaritalStatusSetToMarriedOrPacse,
  IsMaritalStatusSetToPacse,
  IsNationalitySetNotToFrench,
  IsResidenceSetNotToFrench,
  IsUSCitizen,
  ResponseStore,
} from "@stores/mod.ts";
import { InputCheckboxSolutionsInvestmentInterest } from "@sapians/AssetAssessmentForm/Form/FormInputsSection1Elements/InputCheckboxSolutionsInvestmentInterest/InputCheckboxSolutionsInvestmentInterest.tsx";
export const AssetAssessmentFormSection1 = ({
  onSuccessCallback,
  onErrorCallback,
  lastCheckedAt = 0,
  formResponsesValue,
}: AssetAssessmentFormSectionPropsType) => {
  const containerClassName = (num: number) => `grid lg:grid-cols-${num} gap-l`;
  const containerBottomPadding = "l";
  let email = "";
  if (window && window.sessionStorage) {
    email = window.sessionStorage.getItem("EMAIL") as string;
  }

  const renderCallOutContent = (id: string | null, isFromUS = false) => {
    return id === "taxResidence" && isFromUS
      ? (dispatchInputData(
        "taxResidenceUsInformation",
      ) as CallOutItemType)
        .content.innerHTML
      : (id === "taxResidence"
        ? (dispatchInputData(
          "taxResidenceOutsideFranceInformation",
        ) as CallOutItemType)
          .content.innerHTML
        : (dispatchInputData("nationalityUsInformation") as CallOutItemType)
          .content.innerHTML);
  };

  useEffect(() => {
    const FRANCE_OVER_THE_WORLD = ["fr", "gp", "mq", "gf", "re", "sx", "yt"];

    if (ResponseStore.value) {
      if (ResponseStore.value["maritalStatus"] === "pacse") {
        IsMaritalStatusSetToPacse.value = true;
        IsMaritalStatusSetToMarriedOrPacse.value = true;
      } else if (ResponseStore.value["maritalStatus"] === "marie") {
        IsMaritalStatusSetToMarried.value = true;
        IsMaritalStatusSetToMarriedOrPacse.value = true;
      }

      for (const state of FRANCE_OVER_THE_WORLD) {
        if (
          ResponseStore.value["nationality"] &&
          ResponseStore.value["nationality"] !== state
        ) {
          IsNationalitySetNotToFrench.value = true;
          break;
        }

        IsNationalitySetNotToFrench.value = false;
        break;
      }

      for (const state of FRANCE_OVER_THE_WORLD) {
        if (
          ResponseStore.value["taxResidence"] &&
          ResponseStore.value["taxResidence"] !== state
        ) {
          IsResidenceSetNotToFrench.value = true;

          ResponseStore.value["taxResidence"] === "us"
            ? IsFromUS.value = true
            : IsFromUS.value = false;
          break;
        }

        IsResidenceSetNotToFrench.value = false;
        break;
      }

      if (ResponseStore.value.nationality === "us") {
        IsUSCitizen.value = true;
      }
    }
  }, []);

  return (
    <Container>
      {/* Part 1 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(3)}
      >
        <RadioInlineGender
          value={dispatchInputDataReceivedFromDB("gender", formResponsesValue)}
          data={dispatchInputData("gender") as RadioBlockItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
        <InputLastname
          value={dispatchInputDataReceivedFromDB(
            "lastName",
            formResponsesValue,
          )}
          data={dispatchInputData("lastName") as InputItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
        <InputFirstname
          value={dispatchInputDataReceivedFromDB(
            "firstName",
            formResponsesValue,
          )}
          data={dispatchInputData("firstName") as InputItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 2 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(2)}
      >
        <Container>
          <InputEmail
            data={dispatchInputData("email") as InputItemType}
            onSuccess={onSuccessCallback}
            onError={onErrorCallback}
            value={email as string}
          />
          <InputCheckboxForEmail
            value={dispatchInputDataReceivedFromDB(
              "getOffers",
              formResponsesValue,
            )}
            data={dispatchInputData("getOffers") as RadioBlockItemType}
            onSuccess={onSuccessCallback}
          />
        </Container>
        <Container>
          <InputPhone
            value={dispatchInputDataReceivedFromDB("phone", formResponsesValue)}
            data={dispatchInputData("phone") as InputItemType}
            onSuccess={onSuccessCallback}
            onError={onErrorCallback}
            lastCheckedAt={lastCheckedAt}
          />
          <InputCheckboxForPhone
            value={dispatchInputDataReceivedFromDB(
              "getCalled",
              formResponsesValue,
            )}
            data={dispatchInputData("getCalled") as RadioBlockItemType}
            onSuccess={onSuccessCallback}
          />
        </Container>
      </Container>
      {/* Part 3 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
      >
        <InputDateOfBirth
          value={dispatchInputDataReceivedFromDB(
            "dateOfBirth",
            formResponsesValue,
          )}
          data={dispatchInputData("dateOfBirth") as DatePickerItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 4 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <RadioNumberOfChildren
          value={dispatchInputDataReceivedFromDB(
            "dependentChildren",
            formResponsesValue,
          )}
          data={dispatchInputData("dependentChildren") as RadioBlockItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 5 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(2)}
      >
        <Container>
          <SelectCountry
            value={dispatchInputDataReceivedFromDB(
              "taxResidence",
              formResponsesValue,
            )}
            data={dispatchInputData("taxResidence") as DropdownItemType}
            onSuccess={onSuccessCallback}
            onError={onErrorCallback}
            lastCheckedAt={lastCheckedAt}
          />
          {IsResidenceSetNotToFrench.value && IsFromUS.value
            ? (
              <Container
                spacing={{ bottom: containerBottomPadding }}
              >
                <CallOut
                  icon={iconExclamation}
                >
                  {renderCallOutContent(
                    "taxResidence",
                    IsFromUS.value,
                  )}
                </CallOut>
              </Container>
            )
            : null}
          {IsResidenceSetNotToFrench.value
            ? (
              <Container
                spacing={{ bottom: containerBottomPadding }}
              >
                <CallOut
                  icon={iconExclamation}
                >
                  {renderCallOutContent("taxResidence")}
                </CallOut>
              </Container>
            )
            : null}
        </Container>
        <Container>
          <SelectNationality
            value={dispatchInputDataReceivedFromDB(
              "nationality",
              formResponsesValue,
            )}
            data={dispatchInputData("nationality") as DropdownItemType}
            onSuccess={onSuccessCallback}
            onError={onErrorCallback}
            lastCheckedAt={lastCheckedAt}
          />
          {IsUSCitizen.value
            ? (
              <Container
                spacing={{ bottom: containerBottomPadding }}
              >
                <CallOut
                  icon={iconExclamation}
                >
                  {renderCallOutContent("nationality")}
                </CallOut>
              </Container>
            )
            : null}
        </Container>
      </Container>
      {/* Part 6 */}
      {/* Part 7 */}
      {/* Part 8 */}
      {/* Part 9 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <RadioProfession
          value={dispatchInputDataReceivedFromDB(
            "profession",
            formResponsesValue,
          )}
          data={dispatchInputData("profession") as RadioBlockItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 10 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <RadioProfessionalStatus
          value={dispatchInputDataReceivedFromDB("status", formResponsesValue)}
          data={dispatchInputData("status") as RadioBlockItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
        <InputCheckboxSolutionsInvestmentInterest
          value={dispatchInputDataReceivedFromDB<string[]>(
            "investmentSolutionInterests",
            formResponsesValue as unknown as Record<string, string[]>,
          ) as string[]}
          data={dispatchInputData(
            "investmentSolutionInterests",
          ) as RadioBlockItemType}
          onSuccess={onSuccessCallback as unknown as (
            id: string,
            value: string[],
          ) => void}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
    </Container>
  );
};
