import { Container } from "../Container/mod.ts";
import { GridItem } from "../Grid/GridItem/GridItem.tsx";
import { Grid } from "../Grid/mod.ts";
import { Paragraph } from "../Paragraph/mod.ts";
import { CallOutPropsType } from "./types.ts";

export const CallOut = (
  { children, variant = "primary", icon, className = "" }: CallOutPropsType,
) => {
  className += " " + "flex";
  return (
    <Container
      className={className.trim()}
      background={{ variant: variant }}
      radius={{ all: "xs" }}
      spacing={"xl"}
    >
      <Container spacing={{ right: "m" }}>
        {icon}
      </Container>
      <Container>
        {typeof children === "string"
          ? (
            <Paragraph variant={variant}>
              {children}
            </Paragraph>
          )
          : children}
      </Container>
    </Container>
  );
};
