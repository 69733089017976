[
  {
    "id": "signerIdentification",
    "reference": "signerIdentification",
    "element": "Section",
    "isRelatedItem": false,
    "label": "Pour commencer, nous avons besoin de mieux vous connaître"
  },
  {
    "id": "gender",
    "reference": "gender",
    "label": "Vous êtes ?",
    "element": "RadioInline",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": true,
    "disabled": false,
    "content": {
      "choices": [
        {
          "label": "Monsieur",
          "value": "monsieur"
        },
        {
          "label": "Madame",
          "value": "madame"
        },
        {
          "label": "Non précisé",
          "value": "non-precise"
        }
      ]
    }
  },
  {
    "id": "lastName",
    "reference": "lastName",
    "label": "Nom",
    "element": "Input",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": true,
    "disabled": false,
    "placeholder": "Votre nom ...",
    "constraints": [
      {
        "type": "isValidString",
        "options": {
          "regex": "//TODO"
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "firstName",
    "reference": "firstName",
    "label": "Prénom",
    "element": "Input",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": true,
    "disabled": false,
    "placeholder": "Votre prénom ...",
    "constraints": [
      {
        "type": "isValidString",
        "options": {
          "regex": "//TODO bloquer les caractères non valides selon l'encodage"
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "email",
    "reference": "email",
    "label": "Adresse e-mail",
    "element": "Email",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": true
    },
    "required": false,
    "disabled": true,
    "placeholder": "Votre adresse e-mail ..."
  },
  {
    "id": "phone",
    "reference": "phone",
    "label": "Téléphone",
    "element": "Phone",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": false,
    "disabled": false,
    "placeholder": "X XX XX XX XX",
    "content": {
      "filename": "List/Phone/Phone.jsx"
    }
  },
  {
    "id": "getOffers",
    "reference": "getOffers",
    "element": "CheckboxesInline",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": false,
    "disabled": false,
    "content": {
      "choices": [
        {
          "label": "J'accepte de recevoir des propositions commerciales par e-mail.",
          "value": "i-agree"
        }
      ]
    }
  },
  {
    "id": "getCalled",
    "reference": "getCalled",
    "element": "CheckboxesInline",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": false,
    "disabled": false,
    "content": {
      "choices": [
        {
          "label": "Je souhaite être rappelé par un expert.",
          "value": "i-agree"
        }
      ]
    }
  },
  {
    "id": "dateOfBirth",
    "reference": "dateOfBirth",
    "label": "Date de naissance",
    "element": "DatePicker",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": true,
    "disabled": false,
    "placeholder": "JJ/MM/AAAA",
    "config": {
      "format": "dd/mm/yyyy"
    },
    "constraints": [
      {
        "type": "isNotAfter",
        "options": {
          "format": "dd/mm/yyyy",
          "limit": "01/01/2023"
        },
        "message": "Date trop récente"
      },
      {
        "type": "isNotBefore",
        "options": {
          "format": "dd/mm/yyyy",
          "limit": "01/01/1923"
        },
        "message": "Date trop ancienne"
      }
    ]
  },
  {
    "id": "dependentChildren",
    "reference": "dependentChildren",
    "label": "Nombre d'enfants à charge",
    "element": "RadioInline",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": true,
    "disabled": false,
    "content": {
      "choices": [
        {
          "label": "Aucun",
          "value": "0"
        },
        {
          "label": "1",
          "value": "1"
        },
        {
          "label": "2",
          "value": "2"
        },
        {
          "label": "3",
          "value": "3"
        },
        {
          "label": "4",
          "value": "4"
        },
        {
          "label": "5 ou plus",
          "value": "5"
        }
      ]
    }
  },
  {
    "id": "taxResidence",
    "reference": "taxResidence",
    "label": "Résidence fiscale",
    "element": "Dropdown",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "placeholder": "Choisir ...",
    "content": {
      "filename": "List/Country/country.json"
    },
    "relatedItems": [
      {
        "in": [
          "us"
        ],
        "items": [
          {
            "id": "taxResidenceUsInformation",
            "reference": "taxResidenceUsInformation"
          }
        ]
      },
      {
        "notIn": [
          "fr"
        ],
        "items": [
          {
            "id": "taxResidenceOutsideFranceInformation",
            "reference": "taxResidenceOutsideFranceInformation"
          }
        ]
      }
    ]
  },
  {
    "id": "nationality",
    "reference": "nationality",
    "label": "Nationalité",
    "element": "Dropdown",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "success": {
      "isEnabled": false
    },
    "required": false,
    "disabled": false,
    "placeholder": "Choisir ...",
    "content": {
      "filename": "List/Country/country.json"
    },
    "relatedItems": [
      {
        "in": [
          "us"
        ],
        "items": [
          {
            "id": "nationalityResidenceUsInformation",
            "reference": "nationalityResidenceUsInformation"
          }
        ]
      }
    ]
  },
  {
    "id": "taxResidenceOutsideFranceInformation",
    "reference": "taxResidenceOutsideFranceInformation",
    "element": "CallOut",
    "isRelatedItem": true,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "content": {
      "icon": "IconWarning",
      "innerHTML": "Si vous êtes résident fiscal étranger, sachez que l’assurance vie Luxembourgeoise présente de nombreux atouts en terme de sécurité, d’univers d’investissement et de fiscalité. La fiscalité applicable est celle du pays de résidence fiscale du souscripteur. De ce fait, en cas d'expatriation, inutile de rentrer dans le jeu de la convention internationale et des crédits d'impôt parfois associés."
    }
  },
  {
    "id": "taxResidenceUsInformation",
    "reference": "taxResidenceUsInformation",
    "element": "CallOut",
    "isRelatedItem": true,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "content": {
      "icon": "IconWarning",
      "innerHTML": "Si vous êtes résident fiscal étranger, sachez que l’assurance vie Luxembourgeoise présente de nombreux atouts en terme de sécurité, d’univers d’investissement et de fiscalité. La fiscalité applicable est celle du pays de résidence fiscale du souscripteur. De ce fait, en cas d'expatriation, inutile de rentrer dans le jeu de la convention internationale et des crédits d'impôt parfois associés."
    }
  },
  {
    "id": "nationalityUsInformation",
    "reference": "nationalityUsInformation",
    "element": "CallOut",
    "isRelatedItem": true,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "content": {
      "icon": "IconWarning",
      "innerHTML": "Malheureusement, votre nationalité ne nous permettra pas de vous donner accès à nos solutions."
    }
  },
  {
    "id": "profession",
    "reference": "profession",
    "label": "Profession",
    "element": "RadioBlock",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "content": {
      "choices": [
        {
          "label": "Agriculteur exploitant",
          "value": "agriculteur-exploitant"
        },
        {
          "label": "Artisan",
          "value": "artisan"
        },
        {
          "label": "Commerçant",
          "value": "commercant"
        },
        {
          "label": "Chef d'entreprise",
          "value": "chef-d-entreprise"
        },
        {
          "label": "Cadre ou profession intellectuelle supérieure",
          "value": "cadre-ou-profession-intellectuelle-supérieure"
        },
        {
          "label": "Profession intermédiaire",
          "value": "profession-intermediaire"
        },
        {
          "label": "Ouvrier",
          "value": "ouvrier"
        },
        {
          "label": "Retraité",
          "value": "retraite"
        },
        {
          "label": "Sans activité professionnelle",
          "value": "sans-activite-professionnelle"
        }
      ]
    }
  },
  {
    "id": "status",
    "reference": "status",
    "label": "Statut",
    "element": "RadioBlock",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "content": {
      "choices": [
        {
          "label": "Salarié",
          "value": "salarie"
        },
        {
          "label": "Profession libérale",
          "value": "profession-liberale"
        },
        {
          "label": "Chef d'entreprise",
          "value": "chef-d-entreprise"
        },
        {
          "label": "Indépendant",
          "value": "independant"
        }
      ]
    }
  },
  {
    "id": "investmentSolutionInterests",
    "reference": "investmentSolutionInterests",
    "label": "Avez-vous un intérêt pour les solutions d’investissement suivantes ?",
    "element": "RadioBlock",
    "isRelatedItem": false,
    "section": {
      "id": "signerIdentification",
      "reference": "signerIdentification"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "content": {
      "choices": [
        {
          "label": "Marchés financiers",
          "value": "marche-financiers"
        },
        {
          "label": "Private-equity",
          "value": "private-equity"
        },
        {
          "label": "Dette privée",
          "value": "dette-privee"
        },
        {
          "label": "Infrastructures",
          "value": "infrastructures"
        },
        {
          "label": "Immobilier d’entreprise",
          "value": "immobilier-d-entreprise"
        },
        {
          "label": "Cryptomonnaies",
          "value": "cryptomonnaies"
        }
      ]
    }
  },
  {
    "id": "estateAssessment",
    "reference": "estateAssessment",
    "element": "Section",
    "label": "Faisons le point sur votre patrimoine"
  },
  {
    "id": "liquidAssets",
    "reference": "liquidAssets",
    "label": "Vos liquidités (comptes courants, livret A, livrets liquides, LDD)",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "savings",
    "reference": "savings",
    "label": "Votre épargne financière (Assurance(s)-vie, CTO, PER, PEA, PEE/PERCO)",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "digitalAssets",
    "reference": "digitalAssets",
    "label": "Votre épargne en actifs numériques (cryptos, NFT, token)",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "privateAssets",
    "reference": "privateAssets",
    "label": "Vos investissements non-cotés (private equity, participations, dette privée) hors patrimoine professionnel",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "professionalAssets",
    "reference": "professionalAssets",
    "label": "Valeur patrimoine professionnel",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "isOwnerMainResidence",
    "reference": "isOwnerMainResidence",
    "label": "Êtes-vous propriétaire de votre résidence principale ?",
    "element": "RadioInline",
    "isRelatedItem": false,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "content": {
      "choices": [
        {
          "label": "Oui",
          "value": "oui"
        },
        {
          "label": "Non",
          "value": "non"
        }
      ]
    },
    "relatedItems": [
      {
        "in": [
          "oui"
        ],
        "items": [
          {
            "id": "mainResidenceValue",
            "reference": "mainResidenceValue"
          }
        ]
      }
    ]
  },
  {
    "id": "mainResidenceValue",
    "reference": "mainResidenceValue",
    "label": "Valeur de votre résidence principale",
    "element": "SliderAssistedAmount",
    "isRelatedItem": true,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "isOwnerMoreEstates",
    "reference": "isOwnerMoreEstates",
    "label": "Possédez-vous d'autres biens immobiliers ?",
    "element": "RadioInline",
    "isRelatedItem": false,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "content": {
      "choices": [
        {
          "label": "Oui",
          "value": "oui"
        },
        {
          "label": "Non",
          "value": "non"
        }
      ]
    },
    "relatedItems": [
      {
        "in": [
          "oui"
        ],
        "items": [
          {
            "id": "secondHomeValue",
            "reference": "secondHomeValue"
          },
          {
            "id": "rentalInvestmentValue",
            "reference": "rentalInvestmentValue"
          },
          {
            "id": "annualRentalIncome",
            "reference": "annualRentalIncome"
          }
        ]
      }
    ]
  },
  {
    "id": "secondHomeValue",
    "reference": "secondHomeValue",
    "label": "Valeur résidence secondaire",
    "element": "SliderAssistedAmount",
    "isRelatedItem": true,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "rentalInvestmentValue",
    "reference": "rentalInvestmentValue",
    "label": "Valeur investissement locatif",
    "element": "SliderAssistedAmount",
    "isRelatedItem": true,
    "section": {
      "id": "estateAssessment",
      "reference": "estateAssessment"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "income",
    "reference": "income",
    "element": "Section",
    "label": "Vos revenus"
  },
  {
    "id": "netActivityIncome",
    "reference": "netActivityIncome",
    "label": "Revenus d’activité nets mensuels après prélèvement à la source",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "income",
      "reference": "income"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "1 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "securitiesIncome",
    "reference": "securitiesIncome",
    "label": "Revenus de valeurs mobilières annuels",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "income",
      "reference": "income"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "annualRentalIncome",
    "reference": "annualRentalIncome",
    "label": "Revenus locatifs annuels",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "income",
      "reference": "income"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "otherAnnualIncome",
    "reference": "otherAnnualIncome",
    "label": "Autres revenus annuels",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "income",
      "reference": "income"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "expenses",
    "reference": "expenses",
    "element": "Section",
    "label": "Vos dépenses"
  },
  {
    "id": "currentLoansValues",
    "reference": "currentLoansValues",
    "label": "Capital restant à rembourser sur vos emprunts",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "expenses",
      "reference": "expenses"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ],
    "relatedItems": [
      {
        "notIn": [
          "0",
          null
        ],
        "items": [
          {
            "id": "monthlyLoanPayment",
            "reference": "monthlyLoanPayment"
          }
        ]
      }
    ]
  },
  {
    "id": "monthlyLoanPayment",
    "reference": "monthlyLoanPayment",
    "label": "Mensualités des emprunts",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "expenses",
      "reference": "expenses"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "1 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "projects",
    "reference": "projects",
    "element": "Section",
    "label": "Vos projets"
  },
  {
    "id": "monthlySavingsCapacity",
    "reference": "monthlySavingsCapacity",
    "label": "Capacité d’épargne mensuelle",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "projects",
      "reference": "projects"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "safetyPocket",
    "reference": "safetyPocket",
    "label": "Souhaitez-vous constituer une poche de sécurité ?",
    "element": "RadioInline",
    "isRelatedItem": false,
    "section": {
      "id": "projects",
      "reference": "projects"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "content": {
      "choices": [
        {
          "label": "Oui",
          "value": "oui"
        },
        {
          "label": "Non",
          "value": "non"
        }
      ]
    },
    "relatedItems": [
      {
        "in": [
          "oui"
        ],
        "items": [
          {
            "id": "safetyPocketHowMuch",
            "reference": "safetyPocketHowMuch"
          }
        ]
      }
    ]
  },
  {
    "id": "safetyPocketHowMuch",
    "reference": "safetyPocketHowMuch",
    "label": "Si oui, quel montant ?",
    "element": "SliderAssistedAmount",
    "isRelatedItem": true,
    "section": {
      "id": "projects",
      "reference": "projects"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "safetyPocketInformation",
    "reference": "safetyPocketInformation",
    "element": "CallOut",
    "isRelatedItem": true,
    "section": {
      "id": "projects",
      "reference": "projects"
    },
    "content": {
      "icon": "IconWarning",
      "innerHTML": "Nous recommandons 6 mois de salaire pour les salariés et 1 an pour les indépendants et chefs d’entreprise."
    }
  },
  {
    "id": "shortTermProjectLiquidity",
    "reference": "shortTermProjectLiquidity",
    "label": "Avez-vous un projet court-terme (0 à 3 ans) nécessitant un capital facilement mobilisable ?",
    "element": "RadioInline",
    "isRelatedItem": false,
    "section": {
      "id": "projects",
      "reference": "projects"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "content": {
      "choices": [
        {
          "label": "Oui",
          "value": "oui"
        },
        {
          "label": "Non",
          "value": "non"
        }
      ]
    },
    "relatedItems": [
      {
        "in": [
          "oui"
        ],
        "items": [
          {
            "id": "shortTermProjectLiquidityHowMuch",
            "reference": "shortTermProjectLiquidityHowMuch"
          }
        ]
      }
    ]
  },
  {
    "id": "shortTermProjectLiquidityHowMuch",
    "reference": "shortTermProjectLiquidityHowMuch",
    "label": "Si oui, quel montant ?",
    "element": "SliderAssistedAmount",
    "isRelatedItem": true,
    "section": {
      "id": "projects",
      "reference": "projects"
    },
    "required": true,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "longTermInvestmentHowMuch",
    "reference": "longTermInvestmentHowMuch",
    "label": "Quel montant souhaitez vous investir pour vos projets long-terme (Au-delà de 6 ans) ?",
    "element": "SliderAssistedAmount",
    "isRelatedItem": false,
    "section": {
      "id": "projects",
      "reference": "projects"
    },
    "required": false,
    "disabled": false,
    "success": {
      "isEnabled": false
    },
    "currency": "€",
    "placeholder": "100 000",
    "config": {
      "unit": "€",
      "gap": 500000,
      "limit": {
        "min": 0,
        "max": 1500000
      }
    },
    "constraints": [
      {
        "type": "isGreaterThan",
        "options": {
          "limit": 0
        },
        "message": "Saisie invalide"
      }
    ]
  },
  {
    "id": "needComplementaryIncome",
    "reference": "needComplementaryIncome",
    "label": "Avez-vous besoin que vos placements génèrent des revenus complémentaires ?",
    "element": "RadioInline",
    "isRelatedItem": false,
    "section": {
      "id": "projects",
      "reference": "projects"
    },
    "success": {
      "isEnabled": false
    },
    "required": true,
    "disabled": false,
    "content": {
      "choices": [
        {
          "label": "Oui",
          "value": "oui"
        },
        {
          "label": "Non",
          "value": "non"
        }
      ]
    }
  }
]
