import { Container } from "../../Container/Container.tsx";
import { Text } from "../../Text/mod.ts";
import { definition } from "../../definition.ts";
import { JSX, useEffect, useSignal } from "../../deps.ts";
import {
  BROWSER_RESET_STYLE,
  DROPDOWN_ARROW_STYLE,
  NBSP_STRING,
  STATE_COLORS,
} from "../constants.ts";
import { InputSelectPropsType } from "../types.ts";

export const InputSelect = ({
  name,
  value,
  isFocus = false,
  fields,
  label,
  placeholder,
  state = "default",
  isRequired,
  isDisabled,
  feedbackMessage = "",
  className = "",
  helpText,
  onFocus,
  onBlur,
  onChange,
  onClick,
}: InputSelectPropsType) => {
  const focus = useSignal(isFocus);
  const signalValue = useSignal(value);
  const { light } = definition.text.font.primary;

  useEffect(() => {
    signalValue.value = value;
  }, [value]);

  const feedbackMessageClassName = "text-left text-danger-default flex-auto";
  const helpTextClassName =
    "text-right underline pt-xs flex-auto text-base4-default";

  let bgColor = "";
  let contentColor = "";

  if (isDisabled !== undefined && isDisabled == true) {
    bgColor = definition.background.color["disabled"].default;
    contentColor = definition.text.color["disabled"].content;
  } else {
    bgColor = definition.background.color["base1"].default;
    contentColor = definition.text.color["base1"].content;
  }

  let ringColor = definition.ring.color[STATE_COLORS[state]].default;
  let borderColor = definition.border.color[STATE_COLORS[state]].default;

  let containerClassName = `flex items-center h-2xl border-xs ${
    focus.value ? "ring-s" : "ring-none"
  }`;
  const selectClassName = `w-full outline-none ${bgColor}`;

  if (
    state == "default" && signalValue.value !== "" && !isDisabled
  ) {
    borderColor = definition.border.color["base1"].content;
    ringColor = definition.ring.color["base1"].content;
  }

  containerClassName +=
    ` ${borderColor} ${ringColor} ${contentColor} ${bgColor}`;

  className += ` ${light}`;
  className.trim();

  const onChangeHandler = (
    event: JSX.TargetedEvent<HTMLSelectElement>,
  ) => {
    signalValue.value = event.currentTarget.value;
    onChange && onChange(event);
  };

  const onFocusHandler = (event: JSX.TargetedEvent<HTMLSelectElement>) => {
    onFocus && onFocus(event);
    focus.value = true;
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLSelectElement>) => {
    onBlur && onBlur(event);
    focus.value = false;
  };

  return (
    <Container
      className={className}
    >
      <label>
        <Container className={"block pt-s pb-xs"}>{label}</Container>

        <Container
          spacing={{ horizontal: "xs" }}
          background={{ variant: "base1" }}
          radius={{ all: "xs" }}
          className={containerClassName}
        >
          <select
            name={name}
            className={selectClassName}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            onClick={onClick}
            disabled={isDisabled}
            required={isRequired}
            style={`${BROWSER_RESET_STYLE} ${DROPDOWN_ARROW_STYLE}`}
          >
            <option value={""}>
              {placeholder}
            </option>
            {fields.map(({ label, value }) => (
              value && value === signalValue.value
                ? (
                  <option
                    value={value as string}
                    selected={true}
                  >
                    {label}
                  </option>
                )
                : (label === "------------"
                  ? (
                    <option disabled>
                      {label}
                    </option>
                  )
                  : (
                    <option value={value as string}>
                      {label}
                    </option>
                  ))
            ))}
          </select>
        </Container>
      </label>
      <Container className="flex flex-row w-full">
        {feedbackMessage !== ""
          ? (
            <Text
              size={"s"}
              className={feedbackMessageClassName}
              element="div"
            >
              {feedbackMessage}
            </Text>
          )
          : NBSP_STRING}
        {helpText
          ? (
            <Container
              className={helpTextClassName}
              element="div"
            >
              {helpText}
            </Container>
          )
          : null}
      </Container>
    </Container>
  );
};
