import { DatePickerItemType } from "@config/app/questionTemplates/mod.ts";
import { InputFormTypeAllType } from "../../../types.ts";
import { InputFormTypeDate } from "../../BasicElements/InputFormTypeDate.tsx";

export const InputDateOfBirth = (
  {
    value,
    data: {
      id,
      label,
      placeholder,
      required,
      disabled,
      constraints,
    },
    onError,
    onSuccess,
    lastCheckedAt = 0,
  }: InputFormTypeAllType<{
    data: DatePickerItemType;
  }>,
) => {
  return (
    <InputFormTypeDate
      onSuccess={onSuccess}
      onError={onError}
      id={id}
      label={label}
      placeholder={placeholder}
      isRequired={required}
      isDisabled={disabled}
      constraints={constraints}
      value={value}
      lastCheckedAt={lastCheckedAt}
    />
  );
};
