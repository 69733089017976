import { Container } from "@components/Container/mod.ts";
import { SliderAssistedAmountItemType } from "@config/app/questionTemplates/mod.ts";
import {
  AssetAssessmentFormSectionPropsType,
  dispatchInputData,
  dispatchInputDataReceivedFromDB,
} from "../mod.ts";
import { InputCurrentLoansValues, InputMonthlyLoanPayment } from "./mod.ts";

export const AssetAssessmentFormSection4 = ({
  onSuccessCallback,
  onErrorCallback,
  lastCheckedAt = 0,
  formResponsesValue,
}: AssetAssessmentFormSectionPropsType) => {
  const containerClassName = (num: number) => `grid lg:grid-cols-${num} gap-l`;
  const containerBottomPadding = "l";

  return (
    <Container>
      {/* Part 1 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputCurrentLoansValues
          value={dispatchInputDataReceivedFromDB(
            "currentLoansValues",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "currentLoansValues",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 2 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputMonthlyLoanPayment
          value={dispatchInputDataReceivedFromDB(
            "monthlyLoanPayment",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "monthlyLoanPayment",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
    </Container>
  );
};
