import { IconPen } from "@components/Icon/mod.ts";
import { InputTextAndRange, NBSP_STRING } from "@components/Input/mod.ts";
import { JSX, useEffect, useSignal } from "@components/deps.ts";
import { InputFormPropsType } from "../../types.ts";

export const InputFormTypeTextAndRange = (
  {
    label,
    placeholder,
    id = "default",
    state = "default",
    value = "",
    isRequired = false,
    isDisabled = false,
    unit,
    onError,
    onSuccess,
    onInput,
    onBlur,
    lastCheckedAt = 0,
  }: InputFormPropsType & {
    label: string;
    placeholder: string;
    minLength?: number;
    maxLength?: number;
    max?: number;
    min?: number;
    unit?: string;
  },
) => {
  const signalState = useSignal(state);
  const signalValue = useSignal(value);
  const signalFeedbackMessage = useSignal(NBSP_STRING);
  const iconPen = <IconPen size={"s"} />;

  useEffect(() => {
    if (lastCheckedAt != 0) {
      if (!checkError()) {
        onSuccess && onSuccess(id, signalValue.value);
      } else {
        onError && onError(id, signalValue.value);
      }
    }
  }, [lastCheckedAt]);

  useEffect(() => {
    signalValue.value = value;
  }, [value]);

  const onInputHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    if (event.currentTarget.value) {
      event.currentTarget.value = event.currentTarget.value.split(" ").join("");
      value = event.currentTarget.value;
      signalValue.value = value;
    }

    onInput && onInput(event);
    onSuccess && onSuccess(id, signalValue.value);
  };

  const onClick = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalState.value !== "default" ? signalState.value = "default" : null;

    signalFeedbackMessage.value !== NBSP_STRING
      ? signalFeedbackMessage.value = NBSP_STRING
      : null;
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    if (!checkError()) {
      onSuccess && onSuccess(id, signalValue.value);
    } else {
      onError && onError(id, signalValue.value);
    }

    onBlur && onBlur(event);
  };

  const checkError = () => {
    if (signalValue.value === "" && isRequired && !isDisabled) {
      signalState.value = "error";
      signalFeedbackMessage.value = "Vous devez renseigner une valeur.";
      return true;
    }
    signalValue.value = signalValue.value.length > 3
      ? signalValue.value.split(" ").join("")
      : signalValue.value;

    signalState.value = "default";
    signalFeedbackMessage.value = NBSP_STRING;
    return false;
  };

  return (
    <InputTextAndRange
      value={signalValue.value}
      state={signalState.value}
      inputMode={"numeric"}
      label={isRequired ? `${label} *` : label}
      placeholder={placeholder}
      onInput={onInputHandler}
      onBlur={onBlurHandler}
      onClick={onClick}
      feedbackMessage={signalFeedbackMessage.value}
      leadingIcon={iconPen}
      trailingText={unit}
      isRequired={isRequired}
      isDisabled={isDisabled}
    />
  );
};
