import { Container } from "@components/Container/mod.ts";
import { SliderAssistedAmountItemType } from "@config/app/questionTemplates/mod.ts";
import {
  AssetAssessmentFormSectionPropsType,
  dispatchInputData,
  dispatchInputDataReceivedFromDB,
} from "../mod.ts";
import {
  InputAnnualRentalIncome,
  InputNetActivityIncome,
  InputOtherAnnualIncome,
  InputSecuritiesIncome,
} from "./mod.ts";

export const AssetAssessmentFormSection3 = ({
  onSuccessCallback,
  onErrorCallback,
  lastCheckedAt = 0,
  formResponsesValue,
}: AssetAssessmentFormSectionPropsType) => {
  const containerClassName = (num: number) => `grid lg:grid-cols-${num} gap-l`;
  const containerBottomPadding = "l";

  return (
    <Container>
      {/* Part 1 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputNetActivityIncome
          value={dispatchInputDataReceivedFromDB(
            "netActivityIncome",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "netActivityIncome",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 2 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputSecuritiesIncome
          value={dispatchInputDataReceivedFromDB(
            "securitiesIncome",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "securitiesIncome",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 3 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputAnnualRentalIncome
          value={dispatchInputDataReceivedFromDB(
            "annualRentalIncome",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "annualRentalIncome",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 4 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputOtherAnnualIncome
          value={dispatchInputDataReceivedFromDB(
            "otherAnnualIncome",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "otherAnnualIncome",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
    </Container>
  );
};
