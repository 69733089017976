import { InputFormCheckboxType } from "../../../types.ts";
import { RadioBlockItemType } from "@config/app/questionTemplates/types.ts";
import { InputCheckboxHorizontal } from "@components/Input/mod.ts";

export const InputCheckboxSolutionsInvestmentInterest = (
  {
    value,
    data: {
      id,
      label,
      required,
      content: {
        choices,
      },
    },
    onSuccess,
  }: InputFormCheckboxType<{
    data: RadioBlockItemType;
  }>,
) => {
  return (
    <InputCheckboxHorizontal
      legend={required ? `${label} *` : label}
      id={id}
      value={value}
      fields={choices}
      onSuccess={onSuccess}
      isRequired={required}
    />
  );
};
