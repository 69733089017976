import { Container } from "../../../Container/Container.tsx";
import { ProgressBar } from "../../../ProgressBar/ProgressBar.tsx";
import { Text } from "../../../Text/Text.tsx";
import { Button } from "../../Default/Button.tsx";
import { ProgressButtonPropsType } from "./types.ts";
import { IconFloppyDisk } from "../../../Icon/FloppyDisk/IconFloppyDisk.tsx";
import { signal, useEffect } from "@components/deps.ts";
import configSapians from "@config/tailwind/tw.basic.sapians.conf.ts";

export const ButtonSaveWithProgressBar = (
  {
    percent = 0,
    variant = "primary",
    size = "m",
    radius,
    children,
    onClick,
    isDisabled,
    className,
    style,
  }: ProgressButtonPropsType,
) => {
  const isMobileDevice = signal<boolean | null>(null);
  const smBreakPoint = parseInt(configSapians.theme.screens.xl);

  isMobileDevice.value = globalThis.globalThis.innerWidth < smBreakPoint;

  useEffect(() => {
    isMobileDevice.value = globalThis.globalThis.innerWidth < smBreakPoint;

    const windowResizeHandler = () => {
      isMobileDevice.value = globalThis.globalThis.innerWidth < smBreakPoint;
    };

    globalThis.globalThis.addEventListener("resize", windowResizeHandler);

    return () => {
      globalThis.globalThis.addEventListener("resize", windowResizeHandler);
    };
  }, [isMobileDevice]);

  //save current variant
  let usedVariant = variant;
  if (isDisabled) {
    usedVariant = "disabled";
  }
  let spacingRight = "";
  let verticalSpacer = "";
  switch (size) {
    case "s":
      spacingRight = "mr-xs";
      break;
    case "m":
      spacingRight = "mr-s";
      break;
    case "l":
      spacingRight = "mr-m";
      verticalSpacer = "pb-xs";
      break;
  }

  return (
    <Button
      variant={variant}
      size={size}
      radius={radius}
      onClick={onClick}
      className={className}
      isDisabled={isDisabled}
      style={style}
    >
      <Container element="div" className={"m-auto w-max"}>
        <Container
          element="div"
          className={`flex justify-center flex-row items-center ${verticalSpacer}`}
          style={"width: 100%;"}
        >
          <IconFloppyDisk
            size={size}
            variant={usedVariant}
            hover={false}
            focus={false}
            className={`inline ${spacingRight}`}
          />
          <Text
            variant={usedVariant}
            size={isMobileDevice.value ? "m" : size}
            fontStyle="bold"
            element={"div"}
          >
            {`${children} - ${Math.floor(percent)}%`}
          </Text>
        </Container>
        <Container element="div">
          <ProgressBar
            percent={percent}
            variant={usedVariant}
            size="xs"
          />
        </Container>
      </Container>
    </Button>
  );
};
