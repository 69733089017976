import { Container } from "../Container/Container.tsx";
import { definition } from "../definition.ts";
import { ProgressBarPropsType } from "./types.ts";

export const ProgressBar = (
  {
    variant = "primary",
    percent,
    size = "l",
    duration = "s",
  }: ProgressBarPropsType,
) => {
  const percentMax = 100;
  //@ts-ignore: trust me
  const sizeClass = definition.height[size];
  //@ts-ignore: trust me
  const durationClass = definition.animation.duration[duration as string];
  const containerClass = definition.background.color[variant].default;
  const containerBorderClass = definition.border.color[variant].content;
  const progressClass = definition.background.color[variant].content;

  return (
    <Container
      className={` block w-full border-xs relative ${containerClass} ${containerBorderClass} ${sizeClass}`}
      element={"span"}
      radius={{ all: "xl" }}
    >
      <Container
        className={`block absolute h-full transition-all ${durationClass} ${progressClass}`}
        style={`width: ${percent}%`}
        element={"span"}
        radius={{ left: "xl", right: (percent === percentMax) ? "xl" : "none" }}
      />
    </Container>
  );
};
