import { InputFormTypeEmail } from "../../mod.ts";
import { InputFormTypeAllType } from "../../../types.ts";
import { InputItemType } from "@config/app/questionTemplates/types.ts";

export const InputEmail = (
  {
    value,
    data: {
      id,
      label,
      placeholder,
      disabled,
      required,
    },
    onError,
    onSuccess,
  }: InputFormTypeAllType<{
    data: InputItemType;
  }>,
) => {
  return (
    <InputFormTypeEmail
      onSuccess={onSuccess}
      onError={onError}
      id={id}
      label={label}
      placeholder={placeholder}
      value={value}
      isRequired={required}
      isDisabled={disabled}
    />
  );
};
