import { ButtonGetSynthesis } from "@components/Button/ButtonCustom/ButtonGetSynthesis/ButtonGetSynthesis.tsx";
import { ToolbarAssetAssessmentFormPropsType } from "./types.ts";
import { useEffect, useRef, useSignal } from "@components/deps.ts";
import { Paragraph } from "@components/Paragraph/Paragraph.tsx";
import {
  IsDialogConfirmMailBackupSent,
  IsDialogErrorPromptOpened,
  IsFormValid,
} from "@stores/mod.ts";
import { ButtonSaveWithProgressBar } from "@components/Button/ButtonCustom/ButtonSaveWithProgressBar/mod.ts";
import { Helper } from "@services/Helper.ts";

export const ToolbarAssetAssessmentForm = (
  { completionPercentage, onCheck }: ToolbarAssetAssessmentFormPropsType,
) => {
  const referenceDiv = useRef(null);
  const signalWidth = useSignal("100%");
  const signalScrollY = useSignal(0);
  const signalToolbarOffsetTop = useSignal(1000000);

  let elementHeight = 0;

  useEffect(() => {
    if (referenceDiv.current) {
      signalWidth.value = referenceDiv.current.clientWidth + "px";
    }

    //resize
    const windowResizeHandler = () => {
      if (referenceDiv.current) {
        signalWidth.value = referenceDiv.current.clientWidth + "px";
      }
    };
    globalThis.globalThis.addEventListener("resize", windowResizeHandler);
    return () => {
      globalThis.globalThis.removeEventListener("resize", windowResizeHandler);
    };
  });

  useEffect(() => {
    //scroll
    const scrollHandler = () => {
      signalScrollY.value = globalThis.scrollY;
      signalToolbarOffsetTop.value = referenceDiv.current.offsetTop;
    };

    globalThis.globalThis.addEventListener("scroll", scrollHandler, {
      passive: true,
    });

    return () => {
      globalThis.globalThis.removeEventListener("scroll", scrollHandler);
    };
  });

  const getSynthesisHandler = async () => {
    let userEmail = "", userUniqueId = "";

    if (window && window.sessionStorage) {
      userEmail = window.sessionStorage.getItem("EMAIL") as string;
      userUniqueId = window.sessionStorage.getItem("UNIQUEID") as string;
    }

    const res = await Helper.getData("/synthesis/check", {
      email: userEmail,
      uniqueId: userUniqueId, 
    });
    
    if (res.isValid) {
      globalThis.location.href = "/synthesis";
    } else {
      IsDialogErrorPromptOpened.value = true;

      if (onCheck) {
        onCheck();
      }
    }
  };

  const sendEmailToContinueLater = () => {
    IsDialogConfirmMailBackupSent.value = true;
  };

  let classNameContainerGetSynthesisButton = "h-max w-full";
  let classNameContainerSaveButton = "h-3xl";

  if (referenceDiv.current) {
    elementHeight = referenceDiv.current.clientHeight;
  }

  const isInView = (
    elementOffsetTop: number,
    elementHeight: number,
    scrollY: number,
    windowHeight: number,
  ) => {
    return (
      elementOffsetTop + elementHeight > scrollY &&
      elementOffsetTop + elementHeight < scrollY + windowHeight
    );
  };

  if (
    completionPercentage.value == 100 &&
    !isInView(
      signalToolbarOffsetTop.value,
      elementHeight,
      signalScrollY.value,
      globalThis.innerHeight,
    )
  ) {
    classNameContainerGetSynthesisButton +=
      " fixed bottom-none animate-toast-from-bottom";
  }

  if (
    completionPercentage.value > 0 && completionPercentage.value !== 100 &&
    !isInView(
      signalToolbarOffsetTop.value,
      elementHeight,
      signalScrollY.value,
      globalThis.innerHeight,
    )
  ) {
    classNameContainerSaveButton +=
      " fixed bottom-none animate-toast-from-bottom";
  }

  return (
    <div className={"my-[1rem] md:my-[1.5rem] py-l px-s sm:px-l md:px-none"}>
      <div
        ref={referenceDiv}
        className={"m-auto flex flex-col gap-s md:w-[50%] xl:w-[23rem]"}
      >
        <div
          className={classNameContainerGetSynthesisButton}
        >
          <div>
            <ButtonGetSynthesis
              className={"w-full"}
              size={"l"}
              variant={completionPercentage.value !== 100
                ? "primary"
                : "accent"}
              style={{ "width": signalWidth.value }}
              onClick={getSynthesisHandler}
            />
          </div>
          {completionPercentage.value !== 100
            ? (
              <Paragraph size={"s"} align={"center"}>
                Veuillez compléter le formulaire pour dévérouiller l'accès à
                votre synthèse patrimoniale.
              </Paragraph>
            )
            : null}
        </div>
        <div className={classNameContainerSaveButton}>
          {completionPercentage.value !== 100
            ? (
              <ButtonSaveWithProgressBar
                variant={"accent"}
                size={"l"}
                percent={completionPercentage.value}
                style={{ "width": signalWidth.value }}
                onClick={sendEmailToContinueLater}
              >
                Poursuivre plus tard
              </ButtonSaveWithProgressBar>
            )
            : null}
        </div>
      </div>
    </div>
  );
};
