import { Input, NBSP_STRING, STATE_COLORS } from "@components/Input/mod.ts";
import { IconEnvelope } from "@components/Icon/mod.ts";
import { definition } from "@components/definition.ts";
import { InputFormPropsType } from "../../types.ts";
import { JSX, useEffect, useSignal } from "@components/deps.ts";
import { isEmail, isEmpty } from "@validator";

export const InputFormTypeEmail = (
  {
    label,
    id = "default",
    state = "default",
    onFocus,
    onBlur,
    onKeyDown,
    onInput,
    onError,
    placeholder,
    onSuccess,
    helpText,
    isDisabled = false,
    isFocus = false,
    isRequired = false,
    className,
    value = "",
  }: InputFormPropsType & {
    label: string;
  },
) => {
  if (!value) {
    value = "";
  }
  const signalState = useSignal(state);
  const signalValue = useSignal(value);
  const signalFeedbackMessage = useSignal(NBSP_STRING);

  //check onload
  useEffect(() => {
    if (signalValue.value !== "") {
      if (isEmail(signalValue.value, []) && onSuccess) {
        onSuccess(id, signalValue.value);
      } else if (onError) {
        onError(id, signalValue.value);
      }
    }
  }, []);

  const onClickHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalValue.value = event.currentTarget.value;

    if (isEmail(signalValue.value, []) && onSuccess) {
      onSuccess(id, signalValue.value);
    }
  };

  const onInputHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalValue.value = event.currentTarget.value;

    if (isEmail(signalValue.value, []) && onSuccess) {
      onSuccess(id, signalValue.value);
    } else if (onError) {
      onError(id, signalValue.value);
    }
    signalFeedbackMessage.value = NBSP_STRING;
    signalState.value = "default";
    if (onInput) {
      onInput(event);
    }
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    checkError();
    if (onBlur) {
      onBlur(event);
    }
  };

  const onKeyDownHandler = (
    event: JSX.TargetedEvent<HTMLInputElement, KeyboardEvent>,
  ) => {
    signalValue.value = event.currentTarget.value;
    if (event.key === "Enter") {
      checkError();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const checkError = () => {
    if (isEmpty(signalValue.value, [])) {
      return;
    }
    if (!isEmail(signalValue.value, []) && !isEmpty(signalValue.value, [])) {
      signalFeedbackMessage.value = "adresse e-mail incorrect.";
      if (onError) {
        onError(id, signalValue.value);
      }
      signalState.value = "error";
      return;
    }
    if (
      !isEmail(signalValue.value, []) && isEmpty(signalValue.value, []) &&
      isRequired
    ) {
      signalFeedbackMessage.value = "adresse e-mail obligatoire.";
      if (onError) {
        onError(id, signalValue.value);
      }
      signalState.value = "error";
      return;
    }
  };

  let classNameIconEnvelope =
    definition.svg.fill[STATE_COLORS[signalState.value]].default;
  if (
    signalState.value == "default" && !isEmpty(signalValue.value, []) &&
    !isDisabled
  ) {
    classNameIconEnvelope = definition.svg.fill["base1"].content;
  }
  if (isDisabled) {
    classNameIconEnvelope = definition.svg.fill["disabled"].content;
  }

  const envelopIcon = (
    <IconEnvelope size={"m"} className={classNameIconEnvelope} />
  );
  return (
    <Input
      state={signalState.value}
      value={signalValue.value}
      feedbackMessage={signalFeedbackMessage.value}
      type={"email"}
      inputMode={"email"}
      label={isRequired ? `${label} *` : label}
      placeholder={placeholder}
      leadingIcon={envelopIcon}
      isFocus={isFocus}
      helpText={helpText}
      onInput={onInputHandler}
      onKeyDown={onKeyDownHandler}
      onFocus={onFocus}
      onClick={onClickHandler}
      onBlur={onBlurHandler}
      isRequired={isRequired}
      isDisabled={isDisabled}
      className={className}
      isSelectedOnClick={false}
    />
  );
};
