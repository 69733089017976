import { Input, NBSP_STRING } from "@components/Input/mod.ts";
import { JSX, signal, useSignal } from "@components/deps.ts";
import { InputFormPropsType } from "../../types.ts";
import { useEffect } from "preact/hooks";

export const InputFormTypeText = (
  {
    label,
    placeholder,
    minLength = 2,
    maxLength = 100,
    id = "default",
    state = "default",
    value = "",
    isDisabled = false,
    isRequired = false,
    onError,
    onSuccess,
    onInput,
    onBlur,
    lastCheckedAt = 0,
  }: InputFormPropsType & {
    label: string;
    placeholder: string;
    minLength?: number;
    maxLength?: number;
  },
) => {
  const signalState = useSignal(state);
  const signalValue = useSignal(value);
  const signalFeedbackMessage = useSignal(NBSP_STRING);

  useEffect(() => {
    if (lastCheckedAt != 0) {
      if (!checkError()) {
        onSuccess && onSuccess(id, signalValue.value);
      } else {
        onError && onError(id, signalValue.value);
      }
    }
  }, [lastCheckedAt]);

  useEffect(() => {
    signalValue.value = value;
  }, [value]);

  const onClickHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalValue.value = event.currentTarget.value;

    if (
      signalState.value === "error" &&
      signalFeedbackMessage.value !== NBSP_STRING
    ) {
      signalState.value = "default";
      signalFeedbackMessage.value = NBSP_STRING;
    }
  };

  const onInputHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalValue.value = event.currentTarget.value;

    if (!checkErrorOnTyping()) {
      onSuccess && onSuccess(id, signalValue.value);
    } else {
      onError && onError(id, signalValue.value);
    }

    onInput && onInput(event);
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    if (!checkError()) {
      onSuccess && onSuccess(id, signalValue.value);
    } else {
      onError && onError(id, signalValue.value);
    }

    onBlur && onBlur(event);
  };

  const checkError = () => {
    signalState.value = "default";
    signalFeedbackMessage.value = NBSP_STRING;

    if (isRequired) {
      if (signalValue.value.length === 0) {
        signalState.value = "error";
        signalFeedbackMessage.value = "Vous devez renseigner une valeur.";
        return true;
      }
    }

    if (minLength) {
      if (signalValue.value.length < minLength) {
        signalState.value = "error";
        signalFeedbackMessage.value =
          `${minLength} caractères minimum autorisés.`;
        return true;
      }
    }

    return checkErrorOnTyping();
  };

  const checkErrorOnTyping = () => {
    signalState.value = "default";
    signalFeedbackMessage.value = NBSP_STRING;

    if (maxLength) {
      if (signalValue.value.length > maxLength) {
        signalValue.value = signalValue.value.substring(0, maxLength);
        signalState.value = "error";
        signalFeedbackMessage.value =
          `${maxLength} caractères maximum autorisés.`;

        return true;
      }
    }
    return false;
  };

  return (
    <Input
      value={signalValue.value}
      state={signalState.value}
      label={isRequired ? `${label} *` : label}
      placeholder={placeholder}
      onInput={onInputHandler}
      onBlur={onBlurHandler}
      onClick={onClickHandler}
      feedbackMessage={signalFeedbackMessage.value}
      isRequired={isRequired}
      isDisabled={isDisabled}
    />
  );
};
