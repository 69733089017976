import { InputFormTypeSelect } from "../../mod.ts";
import { InputFormTypeAllType } from "../../../types.ts";
import { DropdownItemType } from "@config/app/questionTemplates/types.ts";
import { countries } from "@services/translation/countries.ts";
import { Helper } from "@services/Helper.ts";
//import { dispatchCountries } from "../../mod.ts";

export const SelectNationality = (
  {
    value,
    data: {
      id,
      label,
      required,
      disabled,
      reference,
      placeholder,
    },
    onError,
    onSuccess,
    lastCheckedAt = 0,
  }: InputFormTypeAllType<{
    data: DropdownItemType;
  }>,
) => {
  const countriesSorted = Helper.sortCountries(countries);

  return (
    <InputFormTypeSelect
      onSuccess={onSuccess}
      onError={onError}
      id={id}
      name={reference}
      label={label}
      placeholder={placeholder}
      fields={countriesSorted}
      value={value}
      isRequired={required}
      isDisabled={disabled}
      lastCheckedAt={lastCheckedAt}
    />
  );
};
