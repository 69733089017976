import { IconCalendar } from "@components/Icon/mod.ts";
import { Input, NBSP_STRING, STATE_COLORS } from "@components/Input/mod.ts";
import { definition } from "@components/definition.ts";
import { JSX, useEffect, useSignal } from "@components/deps.ts";
import { isAfter, isBefore, isDate } from "@validator";
import { InputFormPropsType } from "../../types.ts";

import {
  ConstraintType,
  DateLimitType,
} from "@config/app/questionTemplates/types.ts";

export const InputFormTypeDate = (
  {
    label,
    id = "default",
    state = "default",
    onFocus,
    onBlur,
    onKeyDown,
    onKeyUp,
    onInput,
    onError,
    placeholder,
    onSuccess,
    helpText,
    isDisabled = false,
    isFocus = false,
    isRequired = false,
    className,
    value = "",
    constraints,
    //TODO: only dd/mm/yyyy is currently supported
    format = "dd/mm/yyyy",
    lastCheckedAt = 0,
  }: InputFormPropsType & {
    format?: string;
    label: string;
    constraints: ConstraintType<DateLimitType>[];
  },
) => {
  const delimiter = "/";
  const formatLength = format.length;
  const signalState = useSignal(state);
  const signalValue = useSignal(value);
  const signalFeedbackMessage = useSignal(NBSP_STRING);

  //check onload
  useEffect(() => {
    if (signalValue.value !== "") {
      if (isValidDate() && onSuccess) {
        onSuccess(id, signalValue.value);
      } else if (onError) {
        onError(id, signalValue.value);
      }
    } else if (lastCheckedAt != 0) {
      checkError(true);
    }
  }, [lastCheckedAt]);

  useEffect(() => {
    if (lastCheckedAt != 0) {
      checkError(true);
    }
  }, [lastCheckedAt]);

  useEffect(() => {
    signalValue.value = value;
  }, [value]);

  //TODO: create service checking format for input date component + submit handler
  const onInputHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    signalValue.value = formatDateUserInputV2(event.currentTarget.value);

    if (isValidDate() && onSuccess) {
      onSuccess(id, signalValue.value);
    } else if (onError) {
      onError(id, signalValue.value);
    }
    signalFeedbackMessage.value = NBSP_STRING;
    signalState.value = "default";
    if (onInput) {
      onInput(event);
    }
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    checkError(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const onKeyDownHandler = (
    event: JSX.TargetedEvent<HTMLInputElement, KeyboardEvent>,
  ) => {
    signalValue.value = event.currentTarget.value;
    if (event.key === "Enter") {
      checkError(true);
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const checkError = (checkIfEmpty: boolean) => {
    if (!checkIfEmpty && signalValue.value === "") {
      return;
    }

    if (signalValue.value !== "") {
      if (!isValidDate()) {
        signalFeedbackMessage.value = "Format de date incorrect.";
        if (onError) {
          onError(id, signalValue.value);
        }
        signalState.value = "error";
        return;
      }
      // if (constraints && constraints.length !== 0) {
      //   let hasConstraintError = false;
      //   constraints.forEach((constraint) => {
      //     hasConstraintError = false;
      //     switch (constraint.type) {
      //       case "isNotBefore": {
      //         if (!isNotBefore(constraint.options.limit)) {
      //           signalFeedbackMessage.value = constraint.message;
      //           hasConstraintError = true;

      //         }

      //         break;
      //       }
      //       case "isNotAfter": {
      //         if (!isNotAfter(constraint.options.limit)) {
      //           signalFeedbackMessage.value = constraint.message;
      //           hasConstraintError = true;

      //         }

      //         break;
      //       }
      //     }
      //     if (hasConstraintError) {
      //       if (onError) {
      //         onError(id, signalValue.value);
      //       }
      //       signalState.value = "error";
      //     }
      //     return;
      //   });
      // }
    }
    if (
      signalValue.value === "" && isRequired
    ) {
      signalFeedbackMessage.value = "Vous devez renseigner une date.";
      if (onError) {
        onError(id, signalValue.value);
      }
      signalState.value = "error";
      return;
    }

    return;
  };

  const isValidDate = () => {
    return isDate(signalValue.value, { format: format, delimiter });
  };

  const isNotBefore = (limit: string) => {
    return !isBefore(signalValue.value, limit);
  };

  const isNotAfter = (limit: string) => {
    return !isAfter(signalValue.value, limit);
  };

  const formatDateUserInputV2 = (input: string) => {
    const inputLength = input.length;
    if (inputLength > formatLength) return input.substring(0, formatLength);
    if (input.slice(-1) !== "/" && /\D\/$/.test(input)) {
      input = input.substring(0, input.length - 3);
    }
    const values = input.split(delimiter).map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 31);
    if (values[1]) values[1] = checkValue(values[1], 12);
    const output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + delimiter : v;
    });
    return output.join("").substring(0, formatLength);
  };

  const checkValue = (value: string, max: number) => {
    if (value.charAt(0) !== "0" || value == "00") {
      let num = parseInt(value);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      value =
        num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
          ? "0" + num
          : num.toString();
    }
    return value;
  };

  let classNameIconCalendar = definition.svg.fill[STATE_COLORS[state]].default;
  if (
    state == "default" && value != "" && !isDisabled
  ) {
    classNameIconCalendar = definition.svg.fill["base1"].content;
  }
  if (isDisabled) {
    classNameIconCalendar = definition.svg.fill["disabled"].content;
  }

  const calendarIcon = (
    <IconCalendar size={"m"} className={classNameIconCalendar} />
  );

  return (
    <Input
      label={isRequired ? `${label} *` : label}
      placeholder={placeholder}
      state={signalState.value}
      value={signalValue.value}
      type={"text"}
      inputMode={"numeric"}
      maxLength={formatLength}
      helpText={helpText}
      isFocus={isFocus}
      onInput={onInputHandler}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDownHandler}
      onBlur={onBlurHandler}
      isRequired={isRequired}
      isDisabled={isDisabled}
      feedbackMessage={signalFeedbackMessage.value}
      trailingIcon={<IconCalendar />}
      className={className}
    />
  );
};
