import { Container } from "../../Container/mod.ts";
import { Text } from "../../Text/mod.ts";
import { definition } from "../../definition.ts";
import { JSX, useEffect, useSignal } from "../../deps.ts";
import { NBSP_STRING, STATE_COLORS } from "../constants.ts";
import { InputRadioPropsType } from "../types.ts";
import { BROWSER_RESET_STYLE } from "../constants.ts";

export const InputRadio = (
  {
    value,
    state = "default",
    feedbackMessage = "",
    legend,
    name,
    className = "",
    isFocus = false,
    isDisabled = false,
    direction = "row",
    fields,
    helpText,
    hidden = true,
    onFocus,
    onBlur,
    onClick,
  }: InputRadioPropsType,
) => {
  const paddingRight = "margin-right: 5px;";

  const focus = useSignal(isFocus);
  const inputValue = useSignal(value);
  const inputsChecked = useSignal([] as boolean[]);
  const { medium, light } = definition.text.font.primary;
  const mediumAndUnderline = medium + " underline underline-offset-4";

  //check onload
  useEffect(() => {
    if (value) {
      inputValue.value = value;
      const booleans: boolean[] = [];

      fields.map((field) => {
        value === field.value ? booleans.push(true) : booleans.push(false);
      });

      inputsChecked.value = [...booleans];
    }
  }, [value]);

  const legendClassName = "text-base1-content pb-xs pt-s block";
  const feedbackMessageClassName = "text-left text-danger-default flex-auto";
  const helpTextClassName =
    "text-right underline pt-xs flex-auto text-base4-default";

  let bgColor = "";
  let contentColor = "";

  if (isDisabled !== undefined && isDisabled == true) {
    bgColor = definition.background.color["disabled"].default;
    contentColor = definition.text.color["disabled"].content;
  } else {
    bgColor = definition.background.color["base1"].default;
    contentColor = definition.text.color["base1"].content;
  }

  let ringColor = definition.ring.color[STATE_COLORS[state]].default;
  let borderColor = definition.border.color[STATE_COLORS[state]].default;

  let containerClassName = `flex py-xs xs:flex-${
    direction === "row" ? "row xs:h-2xl xs:items-center" : "col xs:py-xs"
  } gap-s border-xs ${focus.value ? "ring-s" : "ring-none"}`;

  if (
    state == "default" && inputValue.value !== "" && !isDisabled
  ) {
    borderColor = definition.border.color["base1"].content;
    ringColor = definition.ring.color["base1"].content;
  }

  containerClassName +=
    ` ${bgColor} ${borderColor} ${contentColor} ${ringColor}`;

  className += ` ${light}`;
  className.trim();

  const onFocusHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      focus.value = true;
      onFocus && onFocus(event);
    }
  };

  const onBlurHandler = (event: JSX.TargetedEvent<HTMLInputElement>) => {
    focus.value = false;
    onBlur && onBlur(event);
  };

  const onClickHandler = (
    event: JSX.TargetedEvent<HTMLInputElement>,
    i = 0,
  ) => {
    if (!isDisabled) {
      const currentLabel = event.currentTarget.parentNode as HTMLLabelElement;
      const wrapper = currentLabel.parentNode as HTMLDivElement;
      const booleans: boolean[] = [];

      for (const child of wrapper.children as unknown as HTMLLabelElement[]) {
        (currentLabel === child && event.currentTarget.checked)
          ? booleans.push(true)
          : booleans.push(false);
        i++;
      }

      inputsChecked.value = [...booleans];
      inputValue.value = event.currentTarget.value;

      onClick && onClick(event);
    }
  };

  return (
    <>
      <fieldset
        className={className}
      >
        <legend
          className={legendClassName}
        >
          <Text>{legend}</Text>
        </legend>
        <Container
          spacing={{ horizontal: "xs" }}
          radius={{ all: "xs" }}
          className={containerClassName}
        >
          {fields.map(({ label, value }, key) => {
            return (
              <Container
                element={"label"}
                className={`${isDisabled ? "" : "cursor-pointer"}${
                  inputsChecked.value[key] ? ` ${mediumAndUnderline}` : ""
                }`}
              >
                <input
                  type="radio"
                  name={name}
                  style={hidden
                    ? ` ${BROWSER_RESET_STYLE} position: absolute;`
                    : paddingRight}
                  value={value as string}
                  onClick={onClickHandler}
                  onFocus={onFocusHandler}
                  onBlur={onBlurHandler}
                />
                {label}
              </Container>
            );
          })}
        </Container>
        <Container className="flex flex-row w-full">
          {feedbackMessage !== ""
            ? (
              <Text
                size={"s"}
                className={feedbackMessageClassName}
                element="div"
              >
                {feedbackMessage}
              </Text>
            )
            : NBSP_STRING}
          {helpText
            ? (
              <Container
                className={helpTextClassName}
                element="div"
              >
                {helpText}
              </Container>
            )
            : null}
        </Container>
      </fieldset>
    </>
  );
};
