import {
  InputLongTermInvestmentHowMuch,
  InputSafetyPocketHowMuch,
  InputShortTermProjectLiquidityHowMuch,
  RadioInlineNeedComplementaryIncome,
  RadioInlineSafetyPocket,
  RadioInlineShortTermProjectLiquidity,
} from "./mod.ts";
import { Container } from "@components/Container/mod.ts";
import {
  CallOutItemType,
  RadioInlineItemType,
  SliderAssistedAmountItemType,
} from "@config/app/questionTemplates/mod.ts";
import {
  AssetAssessmentFormSectionPropsType,
  dispatchInputData,
  dispatchInputDataReceivedFromDB,
  iconExclamation,
} from "../mod.ts";
import {
  IsPocketSecuritySetToTrue,
  IsShortTermProjectLiquiditySetToTrue,
  ResponseStore,
} from "@stores/mod.ts";
import { useEffect } from "@components/deps.ts";
import { CallOut } from "@components/CallOut/CallOut.tsx";
import { InputMonthlySavingsCapacity } from "../FormInputsSection5Elements/InputMonthlySavingsCapacity/InputMonthlySavingsCapacity.tsx";

export const AssetAssessmentFormSection5 = ({
  onSuccessCallback,
  onErrorCallback,
  lastCheckedAt = 0,
  formResponsesValue,
}: AssetAssessmentFormSectionPropsType) => {
  const containerClassName = (num: number) => `grid lg:grid-cols-${num} gap-l`;
  const containerBottomPadding = "l";

  useEffect(() => {
    if (ResponseStore.value) {
      ResponseStore.value["safetyPocket"] === "oui"
        ? IsPocketSecuritySetToTrue.value = true
        : null;

      ResponseStore.value["shortTermProjectLiquidity"] === "oui"
        ? IsShortTermProjectLiquiditySetToTrue.value = true
        : null;
    }
  }, []);

  return (
    <Container>
      {/* Part 1 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputMonthlySavingsCapacity
          value={dispatchInputDataReceivedFromDB(
            "monthlySavingsCapacity",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "monthlySavingsCapacity",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 2 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <RadioInlineSafetyPocket
          value={dispatchInputDataReceivedFromDB(
            "safetyPocket",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "safetyPocket",
          ) as RadioInlineItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 3 */}
      {IsPocketSecuritySetToTrue.value
        ? (
          <Container
            spacing={{ bottom: containerBottomPadding }}
            className={containerClassName(1)}
          >
            <CallOut
              icon={iconExclamation}
            >
              {(dispatchInputData(
                "safetyPocketInformation",
              ) as CallOutItemType)
                .content.innerHTML}
            </CallOut>
            <InputSafetyPocketHowMuch
              value={dispatchInputDataReceivedFromDB(
                "safetyPocketHowMuch",
                formResponsesValue,
              )}
              data={dispatchInputData(
                "safetyPocketHowMuch",
              ) as SliderAssistedAmountItemType}
              onSuccess={onSuccessCallback}
              onError={onErrorCallback}
              lastCheckedAt={lastCheckedAt}
            />
          </Container>
        )
        : null}
      {/* Part 4 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <RadioInlineShortTermProjectLiquidity
          value={dispatchInputDataReceivedFromDB(
            "shortTermProjectLiquidity",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "shortTermProjectLiquidity",
          ) as RadioInlineItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 5 */}
      {IsShortTermProjectLiquiditySetToTrue.value
        ? (
          <Container
            spacing={{ bottom: containerBottomPadding }}
            className={containerClassName(1)}
          >
            <InputShortTermProjectLiquidityHowMuch
              value={dispatchInputDataReceivedFromDB(
                "shortTermProjectLiquidityHowMuch",
                formResponsesValue,
              )}
              data={dispatchInputData(
                "shortTermProjectLiquidityHowMuch",
              ) as SliderAssistedAmountItemType}
              onSuccess={onSuccessCallback}
              onError={onErrorCallback}
              lastCheckedAt={lastCheckedAt}
            />
          </Container>
        )
        : null}
      {/* Part 6 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <InputLongTermInvestmentHowMuch
          value={dispatchInputDataReceivedFromDB(
            "longTermInvestmentHowMuch",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "longTermInvestmentHowMuch",
          ) as SliderAssistedAmountItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
      {/* Part 6 */}
      <Container
        spacing={{ bottom: containerBottomPadding }}
        className={containerClassName(1)}
      >
        <RadioInlineNeedComplementaryIncome
          value={dispatchInputDataReceivedFromDB(
            "needComplementaryIncome",
            formResponsesValue,
          )}
          data={dispatchInputData(
            "needComplementaryIncome",
          ) as RadioInlineItemType}
          onSuccess={onSuccessCallback}
          onError={onErrorCallback}
          lastCheckedAt={lastCheckedAt}
        />
      </Container>
    </Container>
  );
};
