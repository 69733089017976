export const countries = [
  { value: "af", label: "Afghanistan" },
  { value: "al", label: "Albanie" },
  { value: "dz", label: "Algérie" },
  { value: "as", label: "Samoa orientales" },
  { value: "ad", label: "Andorre" },
  { value: "ao", label: "Angola" },
  { value: "ai", label: "Anguilla" },
  { value: "aq", label: "Antarctique" },
  { value: "ag", label: "Antigua-et-Barbuda" },
  { value: "ar", label: "Argentine" },
  { value: "am", label: "Arménie" },
  { value: "aw", label: "Aruba" },
  { value: "au", label: "Australie" },
  { value: "at", label: "Autriche" },
  { value: "az", label: "Azerbaïdjan" },
  { value: "bs", label: "Bahamas" },
  { value: "bh", label: "Bahreïn" },
  { value: "bd", label: "Bangladesh" },
  { value: "bb", label: "Barbade" },
  { value: "by", label: "Biélorussie" },
  { value: "be", label: "Belgique" },
  { value: "bz", label: "Bélize" },
  { value: "bj", label: "Bénin" },
  { value: "bm", label: "Bermudes" },
  { value: "bt", label: "Bhoutan" },
  { value: "bo", label: "Bolivie" },
  { value: "ba", label: "Bosnie-Herzégovine" },
  { value: "bw", label: "Botswana" },
  { value: "bv", label: "Bouvet (Île)" },
  { value: "br", label: "Brésil" },
  { value: "io", label: "Territoire britannique de l'Océan Indien" },
  { value: "bn", label: "Bruneï" },
  { value: "bg", label: "Bulgarie" },
  { value: "bf", label: "Burkina Faso" },
  { value: "bi", label: "Burundi" },
  { value: "kh", label: "Cambodge" },
  { value: "cm", label: "Cameroun" },
  { value: "ca", label: "Canada" },
  { value: "cv", label: "Cap-Vert" },
  { value: "ky", label: "Caïmans (Îles)" },
  { value: "cf", label: "République Centrafricaine" },
  { value: "td", label: "Tchad" },
  { value: "cl", label: "Chili" },
  { value: "cn", label: "Chine" },
  { value: "cx", label: "Christmas (Île)" },
  { value: "cc", label: "Cocos/Keeling (Îles)" },
  { value: "co", label: "Colombie" },
  { value: "km", label: "Comores" },
  { value: "cg", label: "Congo" },
  { value: "cd", label: "République Démocratique du Congo" },
  { value: "ck", label: "Cook (Îles)" },
  { value: "cr", label: "Costa Rica" },
  { value: "ci", label: "Côte d'Ivoire" },
  { value: "hr", label: "Croatie" },
  { value: "cu", label: "Cuba" },
  { value: "cy", label: "Chypre" },
  { value: "cz", label: "République Tchèque" },
  { value: "dk", label: "Danemark" },
  { value: "dj", label: "Djibouti" },
  { value: "dm", label: "Dominique" },
  { value: "do", label: "République Dominicaine" },
  { value: "tp", label: "Timor-Oriental" },
  { value: "ec", label: "Equateur" },
  { value: "eg", label: "Egypte" },
  { value: "sv", label: "Salvador" },
  { value: "gq", label: "Guinée Equatoriale" },
  { value: "er", label: "Erythrée" },
  { value: "ee", label: "Estonie" },
  { value: "et", label: "Ethiopie" },
  { value: "fk", label: "Falkland/Malouines (Îles)" },
  { value: "fo", label: "Féroé (Îles)" },
  { value: "fj", label: "Fidji" },
  { value: "fi", label: "Finlande" },
  { value: "fr", label: "France" },
  { value: "gf", label: "Guyane française" },
  { value: "pf", label: "Polynésie française" },
  { value: "tf", label: "Territoires Antarctiques français" },
  { value: "ga", label: "Gabon" },
  { value: "gm", label: "Gambie" },
  { value: "ge", label: "Géorgie" },
  { value: "de", label: "Allemagne" },
  { value: "gh", label: "Ghana" },
  { value: "gi", label: "Gibraltar" },
  { value: "gr", label: "Grèce" },
  { value: "gl", label: "Groënland" },
  { value: "gd", label: "Grenade" },
  { value: "gp", label: "Guadeloupe" },
  { value: "gu", label: "Guam" },
  { value: "gt", label: "Guatemala" },
  { value: "gn", label: "Guinée" },
  { value: "gw", label: "Guinée-Bissau" },
  { value: "gy", label: "Guyana" },
  { value: "ht", label: "Haïti" },
  { value: "hm", label: "Territoire des Îles Heardet McDonald" },
  { value: "va", label: "Vatican" },
  { value: "hn", label: "Honduras" },
  { value: "hk", label: "Hong Kong" },
  { value: "hu", label: "Hongrie" },
  { value: "is", label: "Islande" },
  { value: "in", label: "Inde" },
  { value: "id", label: "Indonésie" },
  { value: "ir", label: "Iran" },
  { value: "iq", label: "Irak" },
  { value: "ie", label: "Irlande" },
  { value: "il", label: "Israël" },
  { value: "it", label: "Italie" },
  { value: "jm", label: "Jamaïque" },
  { value: "jp", label: "Japon" },
  { value: "jo", label: "Jordanie" },
  { value: "kz", label: "Kazakstan" },
  { value: "ke", label: "Kenya" },
  { value: "ki", label: "Kiribati" },
  { value: "kp", label: "Corée (République populaire démocratique)" },
  { value: "kr", label: "Corée (République démocratique)" },
  { value: "kw", label: "Koweït" },
  { value: "kg", label: "Kyrgyzstan" },
  { value: "la", label: "Laos (République populaire démocratique)" },
  { value: "lv", label: "Lettonie" },
  { value: "lb", label: "Liban" },
  { value: "ls", label: "Lesotho" },
  { value: "lr", label: "Libéria" },
  { value: "ly", label: "Libye (Jamahiriya Arabe Libyenne)" },
  { value: "li", label: "Liechtenstein" },
  { value: "lt", label: "Lituanie" },
  { value: "lu", label: "Luxembourg" },
  { value: "mo", label: "Macau" },
  { value: "mk", label: "Macédoine (ancienne République yougoslave)" },
  { value: "mg", label: "Madagascar" },
  { value: "mw", label: "Malawi" },
  { value: "my", label: "Malaysie" },
  { value: "mv", label: "Maldives" },
  { value: "ml", label: "Mali" },
  { value: "mt", label: "Malte" },
  { value: "mh", label: "Marshall (Îles)" },
  { value: "mq", label: "Martinique" },
  { value: "mr", label: "Mauritanie" },
  { value: "mu", label: "Maurice" },
  { value: "yt", label: "Mayotte" },
  { value: "mx", label: "Mexique" },
  { value: "fm", label: "Micronésie (Etats fédérés)" },
  { value: "md", label: "Moldavie" },
  { value: "mc", label: "Monaco" },
  { value: "mn", label: "Mongolie" },
  { value: "ms", label: "Montserrat" },
  { value: "ma", label: "Maroc" },
  { value: "mz", label: "Mozambique" },
  { value: "mm", label: "Myanmar" },
  { value: "na", label: "Namibie" },
  { value: "nr", label: "Nauru" },
  { value: "np", label: "Népal" },
  { value: "nl", label: "Pays-Bas" },
  { value: "an", label: "Antilles néerlandaises" },
  { value: "nc", label: "Nouvelle-Calédonie" },
  { value: "nz", label: "Nouvelle-Zélande" },
  { value: "ni", label: "Nicaragua" },
  { value: "ne", label: "Niger" },
  { value: "ng", label: "Nigéria" },
  { value: "nu", label: "Niue" },
  { value: "nf", label: "Norfolk (Île)" },
  { value: "mp", label: "Mariannes (Îles)" },
  { value: "no", label: "Norvège" },
  { value: "om", label: "Oman" },
  { value: "pk", label: "Pakistan" },
  { value: "pw", label: "Palau" },
  { value: "pa", label: "Panama" },
  { value: "pg", label: "Papouasie Nouvelle-Guinée" },
  { value: "py", label: "Paraguay" },
  { value: "pe", label: "Pérou" },
  { value: "ph", label: "Philippines" },
  { value: "pn", label: "Pitcaïrn" },
  { value: "pl", label: "Pologne" },
  { value: "pt", label: "Portugal" },
  { value: "pr", label: "Porto-Rico" },
  { value: "qa", label: "Qatar" },
  { value: "re", label: "Réunion" },
  { value: "ro", label: "Romania" },
  { value: "ru", label: "Russie (Fédération)" },
  { value: "rw", label: "Rwanda" },
  { value: "kn", label: "Saint Kitts et Nevis" },
  { value: "lc", label: "Sainte Lucie" },
  { value: "vc", label: "Saint Vincent et Grenadines" },
  { value: "ws", label: "Samoa" },
  { value: "sm", label: "San Marin" },
  { value: "st", label: "Sao Tomé et Principe" },
  { value: "sa", label: "Arabie Saoudite" },
  { value: "sn", label: "Sénégal" },
  { value: "sc", label: "Seychelles" },
  { value: "sl", label: "Sierra Léone" },
  { value: "sg", label: "Singapour" },
  { value: "sk", label: "Slovaquie" },
  { value: "si", label: "Slovénie" },
  { value: "sb", label: "Salomon (Îles)" },
  { value: "so", label: "Somalie" },
  { value: "za", label: "Afrique du Sud" },
  { value: "gs", label: "Géorgie du Sud et îles Sandwich du Sud" },
  { value: "es", label: "Espagne" },
  { value: "lk", label: "SriLanka" },
  { value: "sh", label: "Sainte Hélène" },
  { value: "pm", label: "Saint Pierre et Miquelon" },
  { value: "sd", label: "Soudan" },
  { value: "sr", label: "Surinam" },
  { value: "sj", label: "Svalbardet Jan Mayen (Îles)" },
  { value: "sz", label: "Swaziland" },
  { value: "se", label: "Suède" },
  { value: "ch", label: "Suisse" },
  { value: "sy", label: "Syrie (République arabe syrienne)" },
  { value: "tw", label: "Taïwan" },
  { value: "tj", label: "Tadjikistan" },
  { value: "tz", label: "Tanzanie" },
  { value: "th", label: "Thaïlande" },
  { value: "tg", label: "Togo" },
  { value: "tk", label: "Tokelau" },
  { value: "to", label: "Tonga" },
  { value: "tt", label: "Trinidad et Tobago" },
  { value: "tn", label: "Tunisie" },
  { value: "tr", label: "Turquie" },
  { value: "tm", label: "Turkménistan" },
  { value: "tc", label: "Turkset Caicos (Îles)" },
  { value: "tv", label: "Tuvalu" },
  { value: "ug", label: "Ouganda" },
  { value: "ua", label: "Ukraine" },
  { value: "ae", label: "Emirats Arabes Unis" },
  { value: "gb", label: "Royaume-Uni" },
  { value: "us", label: "Etats-Unis d'Amérique" },
  { value: "um", label: "Territoires non incorporés des États-Unis" },
  { value: "uy", label: "Uruguay" },
  { value: "uz", label: "Ouzbékistan" },
  { value: "vu", label: "Vanuatu" },
  { value: "ve", label: "Vénézuela" },
  { value: "vn", label: "Vietnam" },
  { value: "vg", label: "Vierges (Îles)-RU" },
  { value: "vi", label: "Vierges (Îles)-EU" },
  { value: "wf", label: "Wallis et Futuna (Îles)" },
  { value: "eh", label: "Sahara Occidental" },
  { value: "ye", label: "Yemen" },
  { value: "yu", label: "Yougoslavie" },
  { value: "zm", label: "Zambie" },
  { value: "zw", label: "Zimbabwe" },
];
