import { Button } from "../../Default/Button.tsx";
import { ButtonGetSynthesisPropsType } from "./types.ts";

export const ButtonGetSynthesis = (
  {
    variant = "primary",
    size = "m",
    radius,
    onClick,
    isDisabled,
    className,
    style,
  }: ButtonGetSynthesisPropsType,
) => {
  //save current variant
  let usedVariant = variant;
  if (isDisabled) {
    usedVariant = "disabled";
  }
  let spacingLeft = "ml-s";
  let spacingRight = "mr-s";
  let verticalSpacer = "";
  switch (size) {
    case "s":
      spacingLeft = "ml-xs";
      spacingRight = "mr-xs";
      break;
    case "m":
      spacingLeft = "ml-s";
      spacingRight = "mr-s";
      break;
    case "l":
      spacingLeft = "ml-m";
      spacingRight = "mr-m";
      verticalSpacer = "pb-xs";
      break;
  }

  return (
    <Button
      variant={variant}
      size={size}
      radius={radius}
      onClick={onClick}
      className={className}
      isDisabled={isDisabled}
      style={style}
    >
      Obtenir ma synthèse
    </Button>
  );
};
